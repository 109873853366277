import { useApiCreate } from '../../services/api'
import { useParams } from 'react-router-dom'
import CreateMembershipWithResultsForm from '../../components/forms/create-membership-with-results-form'

export function AdminCompanyNewMembershipPage () {
  const { companyId } = useParams()
  const { data, trigger, isLoading, error } = useApiCreate(`/api/admin/companies/${companyId}/memberships`)

  return (
    <>
      <header>
        <h3>Crear nuevo usuario</h3>
      </header>
      <section>
        <CreateMembershipWithResultsForm isLoading={isLoading} trigger={trigger} data={data} error={error} />
      </section>
    </>

  )
}
