import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/use-current-user'
import { useApiMutation } from '../../services/api'
import { Button, Field, Fieldset, Input, Label } from '@headlessui/react'
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline'

function AlreadyLoggedIn ({ currentUser }) {
  return (
    <>
      <h1>bienvenido <span className='current-user user-email'>{currentUser.email}</span></h1>
      <p className='mt-10 text-center text-sm text-gunsmoke-500'>
        ¿quieres acceder con otra cuenta?{' '}<Link to='/logout' className='font-semibold leading-6 text-java-600 hover:text-java-500'>cerrar sessión</Link>
      </p>
    </>
  )
}

function LoginRecoveryForm ({ onSuccess, onError, ...props }) {
  const { origin } = window.location
  console.log('origin', origin)
  const { isMutating, trigger } = useApiMutation('/api/sessions/recovery', { method: 'post' })

  const handleOnSubmit = event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const email = formData.get('email')
    return trigger({ email, origin }, { onSuccess, onError })
      .then(() => event.target.reset())
  }

  return (
    <form id='login-recovery-form' onSubmit={handleOnSubmit} {...props}>
      <Fieldset disabled={isMutating}>
        <Field>
          <Label>correo electrónico</Label>
          <Input
            autoComplete='email'
            name='email'
            required
            type='email'
          />
        </Field>
        <Button type='submit'>enviar link</Button>
      </Fieldset>
    </form>
  )
}

export function LoginRecoveryPage () {
  const currentUser = useCurrentUser()
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  if (currentUser) { return <AlreadyLoggedIn currentUser={currentUser} /> }

  let content
  if (error) {
    content = (
      <div role='alert' data-alert='error'>
        <ShieldExclamationIcon role='img' />
        <p>{error.message}</p>
      </div>
    )
  } else if (success !== undefined) {
    content = (
      <div role='alert' data-alert='success'>
        <ShieldCheckIcon role='img' />
        <p>solicitud recibida</p>
      </div>
    )
  } else {
    content = <LoginRecoveryForm onSuccess={setSuccess} onError={setError} />
  }

  return (
    <>
      <h1>recupera el acceso a tu cuenta</h1>
      {content}
    </>
  )
}
