import { useApiCreate } from '../services/api'

export function NewCompany () {
  const { data, error, isLoading, trigger } = useApiCreate('/api/companies')
  const onSubmit = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const name = formData.get('name')
    trigger({ name })
  }
  return (
    <form onSubmit={onSubmit} className='mt-4'>
      <label>
        Nombre organización:{' '}
      </label>
      <input type='text' name='name' required />
      <button className='mt-4' type='submit'>Crear</button>
      {isLoading && <p>Creating Company...</p>}
      {error && <p>Error creating company: {error.message}</p>}
      {data && <p>Organización Creada: {data.name}</p>}
    </form>
  )
}
