import { NewCompany } from '../../components/company-new.component'

export function AdminNewCompanyPage () {
  return (
    <>
      <header>
        <h2>Crear nueva organización</h2>
      </header>
      <section>
        <NewCompany />
      </section>
    </>
  )
}
