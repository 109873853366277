import { Navigate, useParams } from 'react-router-dom'
import { useApiCreate, useAuthFetch } from '../../../../../services/api'
import { SurveyEventForm } from '../../../../../components/survey-event-form'
import { useContext } from 'react'
import { CurrentMembershipContext } from '../../../../../contexts/current-membership'

export function MembershipCompanyNewSurveyEventPage () {
  const { companyId: paramsCompanyId } = useParams()
  const { companyId: currentCompanyId } = useContext(CurrentMembershipContext)
  const companyId = paramsCompanyId || currentCompanyId
  const { data: memberships } = useAuthFetch(`api/companies/${companyId}/memberships`)
  const { data, isLoading, error, trigger: mutate } = useApiCreate(`/api/companies/${companyId}/survey_events`)
  if (data) { return <Navigate to={`../${data.id}`} /> }

  return (
    <>
      <header>
        <h2>Nueva Encuesta</h2>
      </header>
      <section>
        {error && <p>{error.message}</p>}
        <SurveyEventForm onSubmit={mutate} loading={isLoading} memberships={memberships} />
      </section>
    </>
  )
}
