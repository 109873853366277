import {
  getAuth,
  signInWithEmailAndPassword,
  signOut as _signOut,
  onIdTokenChanged,
  getIdToken,
  verifyPasswordResetCode as _verifyPasswordResetCode,
  confirmPasswordReset as _confirmPasswordReset
} from 'firebase/auth'
import './auth/firebase.js'
import { BupError } from '../lib/errors.js'
import { useCallback, useReducer } from 'react'

export async function initializeAuth () {
  try {
    const auth = getAuth()
    return auth.authStateReady()
      .then(() => console.info('[auth]', 'ready.'))
  } catch (error) {
    throw new BupError({ code: 'auth/cannot-initialize', cause: error })
  }
}

export async function signIn (email, password) {
  try {
    const auth = getAuth()
    return await signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => userCredential.user)
  } catch (error) {
    throw new BupError({ code: (error.code || 'auth/cannot-sign-in'), cause: error })
  }
}

export async function signOut () {
  try {
    const auth = getAuth()
    await _signOut(auth)
  } catch (error) {
    throw new BupError({ code: error.code || 'auth/cannot-sign-out', cause: error })
  }
}

export async function getToken () {
  try {
    const auth = getAuth()
    return getIdToken(auth.currentUser)
  } catch (error) {
    throw new BupError({ code: error.code || 'auth/cannot-get-token', cause: error })
  }
}

export function verifyPasswordResetCode (oobCode) {
  const auth = getAuth()
  return _verifyPasswordResetCode(auth, oobCode)
    .catch(error => {
      throw new BupError({ code: error.code || 'auth/cannot-verify-password-reset-code', cause: error })
    })
}

export function confirmPasswordReset (oobCode, newPassword, confirmNewPassword) {
  if (newPassword !== confirmNewPassword) {
    throw new BupError({ code: 'auth/validation/passwords-do-not-match' })
  }
  const auth = getAuth()
  return _confirmPasswordReset(auth, oobCode, newPassword)
    .catch(error => { throw new BupError({ code: error.code || 'auth/cannot-confirm-password-reset', cause: error }) })
}

export function subscribe (listener) {
  const auth = getAuth()
  const unsusbcribe = onIdTokenChanged(auth, listener)
  return () => unsusbcribe()
}

export function getSnapshot () {
  const auth = getAuth()
  return auth.currentUser
}

const initialState = {
  data: undefined,
  isMutating: false,
  error: null
}

function reducer (state, action) {
  switch (action.type) {
    case 'START':
      return { ...state, isMutating: true, error: null }
    case 'SUCCESS':
      return { ...state, isMutating: false, data: action.payload }
    case 'FAILURE':
      return { ...state, isMutating: false, error: action.payload }
    default:
      return state
  }
}

export function useSignIn () {
  const [state, dispatch] = useReducer(reducer, initialState)

  const trigger = useCallback(async (email, password) => {
    dispatch({ type: 'START' })
    try {
      const data = await signIn(email, password)
      dispatch({ type: 'SUCCESS', payload: data })
    } catch (error) {
      dispatch({ type: 'FAILURE', payload: error })
    }
  }, [])

  return { ...state, trigger }
}

export function useConfirmPasswordReset () {
  const [state, dispatch] = useReducer(reducer, initialState)

  const trigger = useCallback(async (oobCode, newPassword, confirmNewPassword) => {
    dispatch({ type: 'START' })
    try {
      const data = await confirmPasswordReset(oobCode, newPassword, confirmNewPassword)
        .then(() => true)
      dispatch({ type: 'SUCCESS', payload: data })
    } catch (error) {
      dispatch({ type: 'FAILURE', payload: error })
    }
  }, [])

  return { ...state, trigger }
}

export function useVerifyPasswordResetCode () {
  const [state, dispatch] = useReducer(reducer, initialState)

  const trigger = useCallback(async oobCode => {
    dispatch({ type: 'START' })
    try {
      const data = await verifyPasswordResetCode(oobCode)
      dispatch({ type: 'SUCCESS', payload: data })
    } catch (error) {
      dispatch({ type: 'FAILURE', payload: error })
    }
  }, [])

  return { ...state, trigger }
}
