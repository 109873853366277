import { Outlet } from 'react-router-dom'
import { CurrentUserProvider } from '../../contexts/current-user-provider.jsx'

export function RootLayer () {
  return (
    <CurrentUserProvider>
      <Outlet />
    </CurrentUserProvider>
  )
}
