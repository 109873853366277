import { Button, Field, Fieldset, Input, Label } from '@headlessui/react'
import { ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { useSignIn } from '../../services/auth'

export function LoginForm () {
  const { isLoading, error, trigger } = useSignIn()

  // TODO: translate error code
  const alert = error && (
    <div role='alert' data-alert='error small'>
      <ShieldExclamationIcon role='img' />
      <p>{error.code}</p>
    </div>
  )
  const handleOnSubmit = event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    return trigger(formData.get('email'), formData.get('password'))
      .then(() => event.target.reset())
  }

  return (
    <form id='login-form' onSubmit={handleOnSubmit}>
      <Fieldset disabled={isLoading}>
        {alert}
        <Field>
          <Label>correo electrónico</Label>
          <Input
            autoComplete='username email'
            name='email'
            required
            type='email'
          />
        </Field>
        <Field>
          <Label>contraseña</Label>
          <Input
            autoComplete='current-password'
            name='password'
            required
            type='password'
            placeholder='********'
          />
        </Field>
        <Button type='submit'>ingresa</Button>
      </Fieldset>
    </form>
  )
}
