import { useEffect, useState, useSyncExternalStore } from 'react'
import { CurrentUserContext } from './current-user.jsx'
import { subscribe, getSnapshot } from '../services/auth.js'

export const CurrentUserProvider = ({ children }) => {
  const currentUser = useSyncExternalStore(subscribe, getSnapshot)
  const [token, setToken] = useState()
  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then(setToken)
    }
  }, [currentUser])
  let value = null
  if (currentUser && !token) {
    return 'loading...'
  }
  if (currentUser && token) {
    value = { email: currentUser.email, token }
  }
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}
