import { useParams, useSearchParams } from 'react-router-dom'
import surveyQuestions from '../../../data/survey-questions'
import AnswersComponent from '../../../components/survey/answers/answers.component'
import SurveyTabGroup from '../../../components/survey/app/survey-tab-group'
import { SurveyEventProvider, SurveyResponsesContext } from '../../../contexts/survey-event-provider'
import { useContext } from 'react'

const dimensionTabMap = surveyQuestions.reduce((acc, { code }, index) => {
  acc[code] = index
  return acc
}, {})
const tabDimensionMap = surveyQuestions.reduce((acc, { code }, index) => {
  acc[index] = code
  return acc
}, {})

const SurveyResponsesApp = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: surveyResponses } = useContext(SurveyResponsesContext)
  const changeDimension = (index) => {
    setSearchParams({ dimension: tabDimensionMap[index] })
  }
  const list = surveyQuestions.map(({ code, dimension }) => ({ key: code, element: dimension }))

  const panels = surveyQuestions.map(({ questions, code }) => (
    {
      key: code,
      element: (
        questions.map((question) => (
          <div key={question.code}>
            <h4>{question.subdimension}</h4>
            <p>{question.code}: {question.questionText}</p>
            <AnswersComponent
              question={question}
              responses={surveyResponses}
            />
          </div>
        ))
      )
    }
  ))
  return (
    <SurveyTabGroup
      list={list}
      panels={panels}
      selectedIndex={dimensionTabMap[searchParams.get('dimension')] || 0}
      onChange={changeDimension}
      isClickable
    />
  )
}

export const MembershipSurveyResponsesPage = () => {
  const { membershipId, surveyEventId } = useParams()

  return (
    <>
      <header>
        <h2>Mis respuestas</h2>
      </header>
      <section>
        <SurveyEventProvider membershipId={membershipId} surveyEventId={surveyEventId}>
          <SurveyResponsesApp />
        </SurveyEventProvider>
      </section>
    </>
  )
}
