import { useRef } from 'react'
import classes from './create-membership-form.module.scss'
import { useCurrentProfile } from '../../services/api.js'

const CreateMembershipForm = ({ createUser }) => {
  const currentProfile = useCurrentProfile()

  const formRef = useRef()
  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const name = formData.get('name')
    const email = formData.get('email')
    const password = formData.get('password')
    const isManager = formData.get('isManager') === 'on'
    const membership = { name, email, password, isManager }
    createUser({ user: membership })
      .then(() => formRef.current.reset())
  }

  return (
    <form
      id='create-membership-form'
      onSubmit={handleSubmit}
      className={classes.form}
      ref={formRef}
    >
      <div className={classes.formGroup}>
        <label htmlFor='name'>Nombre: </label>
        <input name='name' required autoComplete='off' />
      </div>
      <div className={classes.formGroup}>
        <label htmlFor='email'>Correo electrónico: </label>
        <input name='email' type='email' required autoComplete='off' />
      </div>
      {
        currentProfile.isAdmin && (
          <div className={classes.formCheck}>
            <label htmlFor='isManager'>Es manager: </label>
            <input name='isManager' type='checkbox' />
          </div>
        )
      }
      <div className={classes.formGroup}>
        <label htmlFor='password'>Contraseña: </label>
        <input name='password' type='password' required autoComplete='off' />
      </div>
      <button className={classes.button} type='submit'>Crear usuario</button>
    </form>
  )
}

export default CreateMembershipForm
