import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import routes from './routes.jsx'
import { LoadingFallback } from './components/loading-fallback.component.jsx'

const router = createBrowserRouter(routes)

function App () {
  return <RouterProvider router={router} fallbackElement={<LoadingFallback />} />
}

export default App
