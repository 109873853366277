import { Navigate } from 'react-router-dom'
import { CurrentMembershipContext } from './current-membership.jsx'
import { useCurrentProfile } from '../services/api.js'

export const CurrentMembershipProvider = ({ membershipId, children }) => {
  const currentProfile = useCurrentProfile()
  const currentMembership = currentProfile.memberships.find(membership => membership.id === membershipId)
  if (!currentMembership) return <Navigate to='/user/memberships' replace />

  return (
    <CurrentMembershipContext.Provider value={currentMembership}>
      {children}
    </CurrentMembershipContext.Provider>
  )
}
