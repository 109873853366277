import { Link, useLocation } from 'react-router-dom'

export function NotFound ({ name }) {
  const location = useLocation()
  return (
    <main className='grid min-h-full place-items-center bg-cloud-white-500 px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-user-primary-600'>404</p>
        <pre className='debug text-sm font-semibold text-user-primary-500'>{location.pathname} (matched {name})</pre>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gunsmoke-900 sm:text-5xl'>Page not found</h1>
        <p className='mt-6 text-base leading-7 text-gunsmoke-600'>Sorry, we couldn’t find the page you’re looking for.</p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <Link
            href='..'
            className='rounded-md bg-user-primary-800 px-3.5 py-2.5 text-sm font-semibold text-cloud-white-500 shadow-sm hover:bg-user-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-user-primary-600'
          >
            Go back home
          </Link>
          <a href='#' className='text-sm font-semibold text-gunsmoke-900'>
            Contact support <span aria-hidden='true'>&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}
