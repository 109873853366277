import { Link } from 'react-router-dom'
import { CompanyTable } from '../../components/company-list.component.jsx'
import { useAuthFetch } from '../../services/api'

export function AdminCompaniesPage () {
  const { data, error, isLoading } = useAuthFetch('/api/companies')
  if (isLoading) return <p>Loading Companies...</p>
  if (error) return <p>Error loading companies: {error.message}</p>
  if (!data) return <p>Initializing...</p>
  return (
    <>
      <header>
        <h2>Administración de Empresas</h2>
      </header>
      <section>
        <div className='flex justify-end w-full'>
          <Link className='mb-4' to='new'>Nueva organización</Link>

        </div>
        <CompanyTable companies={data} />
      </section>
    </>
  )
}
