import { Fieldset, Field, Label, Input, Button } from '@headlessui/react'
import { useState } from 'react'

const CompanyMemeberships = ({ onChange, memberships }) => {
  return (
    <div className='w-full border border-gray-300 shadow-lg p-4 rounded-md h-[450px] overflow-y-auto'>
      <h4 className='mb-2'>Participantes</h4>
      <hr />
      {memberships?.map((membership) => (
        <div key={membership.id} className='flex gap-2 items-center w-full mt-2'>
          <input onChange={onChange} type='checkbox' value={membership.id} />
          <label>{membership.person.name}</label>
        </div>
      ))}
    </div>
  )
}

export function SurveyEventForm ({ onSubmit: callback, loading = false, memberships }) {
  const [participants, setParticipants] = useState([])

  const onSubmit = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const values = Object.fromEntries(formData)

    const content = Object.entries(values).reduce((acc, [key, value]) => {
      if (key.startsWith('content.')) acc[key.slice(8)] = value
      return acc
    }, {})

    content.opensAt = new Date(content.opensAt)
    if (content.closesAt.length) {
      content.closesAt = new Date(content.closesAt)
    } else { delete content.closesAt }
    const updateContent = { ...content, participants: participants.sort((a, b) => a - b) }

    const bodyContent = {
      content: {
        title: updateContent.title,
        opensAt: updateContent.opensAt,
        closesAt: updateContent.closesAt
      },
      participants: {
        ids: updateContent.participants
      }
    }
    callback(bodyContent)
  }

  const now = (new Date()).toISOString().slice(0, 16)

  const onChange = (e) => {
    if (!e.target.checked) {
      setParticipants(prev => prev.filter(participant => participant !== e.target.value))
    } else {
      setParticipants(prev => [...prev, e.target.value])
    }
  }

  return (
    <>
      <form onSubmit={onSubmit} className='w-full'>
        <h4>Formulario de creación</h4>
        <Fieldset className='flex justify-between mt-5 w-full' name='content'>
          <Field>
            <Label htmlFor='content.title'>Nombre de la Encuesta: </Label>
            <Input className='w-full' type='text' name='content.title' placeholder='ej: Encuesta de satisfacción' required />
          </Field>
          <Field>
            <Label htmlFor='content.opensAt'>Hora de apertura: </Label>
            <Input className='w-full' type='datetime-local' name='content.opensAt' defaultValue={now} required />
          </Field>
          <Field>
            <Label htmlFor='content.closesAt'>Hora de cierre: </Label>
            <Input className='w-full' type='datetime-local' name='content.closesAt' />
          </Field>
        </Fieldset>
        <Fieldset className='mt-6'>
          <CompanyMemeberships onChange={onChange} memberships={memberships} />
        </Fieldset>
        <Fieldset className='mt-6 flex justify-end'>
          <Button type='submit' disabled={loading}>Crear Nueva Encuesta</Button>
        </Fieldset>
      </form>
      {loading && <p>Creando encuesta...</p>}
    </>
  )
}
// TODO: closeAt must be after opensAt
