import { Tab, TabGroup, TabList, TabPanel, TabPanels, Transition } from '@headlessui/react'

const SurveyTabGroup = ({ list, panels, isClickable = false, ...props }) => {
  const tabs = list.map(({ key, element }) => {
    return (
      <Tab
        as='li' key={key} className={`grow text-left lg:text-center py-2 lg:py-5 text-gunsmoke-200 hidden lg:block data-[selected]:block data-[selected]:text-user-primary-400 data-[selected]:border-b-2 data-[selected]:border-user-secondary-200 data-[selected]:font-bold ${isClickable ? 'cursor-pointer hover:font-bold' : ''}`}
      >
        <span>{element}</span>
      </Tab>
    )
  })

  const tabPanels = panels.map(({ key, element }, index) => {
    return (
      <TabPanel key={key}>
        <Transition show={props.selectedIndex === index} appear>
          <div className='transition-opacity duration-500 data-[closed]:opacity-0'>{element}</div>
        </Transition>
      </TabPanel>
    )
  })

  return (
    <TabGroup {...props}>
      <TabList as='ul' className='flex list-none flex-row flex-wrap justify-between'>
        {tabs}
      </TabList>
      <TabPanels>
        {tabPanels}
      </TabPanels>
    </TabGroup>
  )
}

export default SurveyTabGroup
