import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useApiCreate } from '../services/api'
import Modal from './modal'
import AddResponsesContextProvider from '../contexts/add-responses.context'
import { SurveyResponsesContext } from '../contexts/survey-event-provider'
import SurveyAppQuestions from './survey/app/survey-app-questions'

const SurveyApp = () => {
  const { surveyEventId, membershipId } = useParams()
  const { data: responses } = useContext(SurveyResponsesContext)
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const { trigger: createParticipantStatus } = useApiCreate(`/api/memberships/${membershipId}/survey_events/${surveyEventId}/survey_participant_statuses`)
  const onAfterLastDimension = () => {
    setIsOpen(true)
  }

  const modalText = {
    title: 'Finalizar encuesta',
    description: 'Estás por terminar la encuesta.',
    paragraph: 'Una vez finalizada, no podrás modificar tus respuestas.',
    buttonText: 'Terminar'
  }

  const handleEndSurvey = () => {
    createParticipantStatus({ content: { status: 'finished' } })
      .then(() => setIsOpen(false))
      .then(() => navigate('survey_responses'))
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={modalText.title}
        description={modalText.description}
        paragraph={modalText.paragraph}
        buttonText={modalText.buttonText}
        handleAction={handleEndSurvey}
      />
      <AddResponsesContextProvider>
        <SurveyAppQuestions onAfterLastDimension={onAfterLastDimension} responses={responses} />
      </AddResponsesContextProvider>

    </>
  )
}
export default SurveyApp
