import { Outlet, Navigate } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/use-current-user.js'

export function AuthenticatedLayer () {
  // TODO: check if this could be done at the root layout, maybe within the current user provider
  const currentUser = useCurrentUser()
  switch (currentUser) {
    case undefined:
      throw new Error('AuthenticatedLayer currentUser undefined')
    case null: {
      return <Navigate to='/login' />
    }
    default:
      return <Outlet />
  }
}
