const surveyQuestions = [
  {
    dimension: 'Caracterización',
    code: 'D01',
    questions: [
      {
        subdimension: 'Género',
        code: 'gen',
        questionText: '¿Con qué género te identificas?',
        answersType: 'provided',
        answers: [
          {
            code: 'AO01',
            text: 'Femenino'
          },
          {
            code: 'AO02',
            text: 'Masculino'
          },

          {
            code: 'AO03',
            text: 'Otro'
          },
          {
            code: 'AO04',
            text: 'Prefiero no contestar'
          }
        ],
        type: 'radio'

      },
      {
        code: 'edad',
        subdimension: 'Edad',
        questionText: '¿Cuántos años tienes?',
        answersType: 'provided',
        answers: [
          {
            code: 'AO01',
            text: '18-29'
          },
          {
            code: 'AO02',
            text: '30-44'
          },
          {
            code: 'AO03',
            text: '45-59'
          },
          {
            code: 'AO04',
            text: '60 años o más'
          }

        ],
        type: 'radio'

      }]
  },
  {
    dimension: 'Asesoría informal',
    code: 'D02',
    questions: [
      {
        subdimension: 'Relaciones de asesoría informal',
        code: 'ai1',
        questionText: 'En el trabajo, las personas usualmente recurren a colegas o supervisores(as) para pedir asesoría y/o consejo. En el último año, ¿a quién(es) de tus compañeros(as) de trabajo has acudido para pedir apoyo en temas técnicos o relativos al trabajo?',
        answersType: 'dynamic',
        answers: 'everyone-but-self', // 'everyone-but-self`
        type: 'multiple-choice'
      },
      {
        subdimension: 'Frecuencia de asesoría informal',
        code: 'ai2',
        answersType: 'matrix',
        type: 'matrix',
        questionText: 'Pensando en los(as) colegas que acabas de marcar ¿Qué tan seguido recurres a cada uno de ellos(as) por asesoría o consejos referentes a lo laboral?',
        dependency: 'ai1',
        answersOptions: [
          { code: 'AF01', option: 'Muy poca veces' },
          { code: 'AF02', option: 'Algunas veces' },
          { code: 'AF03', option: 'Muchas veces' }
        ],
        subquestionsType: 'participants'
      }
    ]
  },
  {
    dimension: 'Disposición a compartir información',
    code: 'D03',
    questions: [
      {
        subdimension: 'Percepción de disposición a compartir información',
        code: 'ci',
        questionText: '¿Qué compañeros(as) de trabajo crees que estarían más dispuestos(as) a compartir conocimientos e/o información contigo?',
        answersType: 'dynamic',
        answers: 'static', // 'everyone-but-self`
        type: 'multiple-choice'
      }
    ]
  },
  {
    dimension: 'Comunicación interpersonal',
    code: 'D04',
    questions: [
      {
        subdimension: 'Relaciones de comunicación interpersonal',
        code: 'com1',
        questionText: 'En una semana laboral típica, ¿con quién(es) de tu(s) compañeros(as) de trabajo interactúas de manera personal? Por ejemplo: una conversación social, una conversación telefónica, una conversación por correo electrónico, o algún tipo de reunión.',
        answersType: 'dynamic',
        answers: 'everyone', // 'everyone-but-self`
        type: 'multiple-choice'
      },
      {
        subdimension: 'Frecuencia de comunicación interpersonal',
        code: 'com2',
        answersType: 'matrix',
        type: 'matrix',
        questionText: 'Pensando en los(as) colegas que acabas de marcar ¿Qué tan seguido recurres a cada uno de ellos(as) por asesoría o consejos referentes a lo laboral?',
        dependency: 'com1',
        answersOptions: [
          { code: 'AF01', option: 'Muy poca veces' },
          { code: 'AF02', option: 'Algunas veces' },
          { code: 'AF03', option: 'Muchas veces' }
        ],
        subquestionsType: 'participants'
      }
    ]
  },
  {
    dimension: 'Apoyo',
    code: 'D05',
    questions: [
      {
        subdimension: 'Relaciones de apoyo',
        code: 'ap1',
        questionText: '¿Quién(es) de tu(s) compañeros(as) te entregan apoyo más allá de lo que su rol requiere? Por ejemplo, te ayudan cuando tienes mucha carga laboral o estás ausente; te ayudan con tu trabajo a pesar de que no sea requerido; te apoyan de manera voluntaria durante una semana laboral típica; o te dan sugerencias innovadoras.',
        answersType: 'dynamic',
        answers: 'everyone', // 'everyone-but-self`
        type: 'multiple-choice'
      },
      {
        subdimension: 'Frecuencia de comunicación interpersonal',
        code: 'ap2',
        answersType: 'matrix',
        type: 'matrix',
        questionText: 'Pensando en los(as) colegas que acabas de marcar ¿Qué tan seguido recurres a cada uno de ellos(as) por asesoría o consejos referentes a lo laboral?',
        dependency: 'ap1',
        answersOptions: [
          { code: 'AF01', option: 'Muy poca veces' },
          { code: 'AF02', option: 'Algunas veces' },
          { code: 'AF03', option: 'Muchas veces' }
        ],
        subquestionsType: 'participants'
      }
    ]
  },
  {
    dimension: 'Benevolencia',
    code: 'D06',
    questions: [
      {
        subdimension: 'Relaciones de benevolencia',
        code: 'ben1',
        questionText: '¿Quién(es) de tus compañeros(as) de trabajo consideras que se preocupan por tu bienestar personal? Es decir, percibes que tus necesidades y deseos individuales son importantes para estas personas y que se preocupan por lo que es importante para ti.',
        answersType: 'dynamic',
        answers: 'static', // 'everyone-but-self`
        type: 'multiple-choice'
      }
    ]
  },
  {
    dimension: 'Acoso',
    code: 'D07',
    questions: [
      {
        subdimension: 'Acoso Laboral',
        code: 'at1',
        answersOptions: [
          { option: 'Nunca', code: 'AF01' },
          { option: 'De vez en cuando', code: 'AF02' },
          { option: 'Semanalmente', code: 'AF03' },
          { option: 'Mensualmente', code: 'AF04' },
          { option: 'A diario', code: 'AF05' }
        ],
        subquestions: [
          { code: 'AL1', text: 'Me han restringido información que afecta a mi rendimiento' },
          { code: 'AL2', text: 'He sido ridiculizado(a) en relación con mi trabajo' },
          { code: 'AL3', text: 'Me han ordenado tareas por debajo de mis competencias' },
          { code: 'AL4', text: 'Me han recordado constantemente cualquier error o fallo que he cometido' },
          { code: 'AL5', text: 'Mis opiniones y puntos de vista han sido ignorados' },
          { code: 'AL6', text: 'He sido controlado(a) y/o vigilado(a) en extremo' },
          { code: 'AL7', text: 'He recibido presiones para que no reclame algo que por derecho me corresponde (baja por enfermedad, vacaciones, etc.)' },
          { code: 'AL8', text: 'He sido expuesto(a) a una carga de trabajo imposible de llevar a cabo' }
        ],
        questionText: '¿En general, ¿Con qué frecuencia...?',
        answersType: 'matrix',
        answers: 'everyone', // 'everyone-but-self`
        type: 'matrix'
      },
      {
        subdimension: 'Acoso Personal',
        code: 'at2',

        answersOptions: [
          { option: 'Nunca', code: 'AF01' },
          { option: 'De vez en cuando', code: 'AF02' },
          { option: 'Semanalmente', code: 'AF03' },
          { option: 'Mensualmente', code: 'AF04' },
          { option: 'A diario', code: 'AF05' }
        ],
        subquestions: [
          { code: 'AP1', text: 'He sido objeto de amenazas de violencia o abuso físico' },
          { code: 'AP2', text: 'Se han extendido chismes y rumores sobre mi' },
          { code: 'AP3', text: 'He sido ignorado(a), excluido(a) o aislado(a) físicamente' },
          { code: 'AP4', text: 'He sido víctima de insultos u ofensas en relación a mi forma de ser, mis actitudes o mi vida privada' },
          { code: 'AP5', text: 'He sido objeto de gritos o reacciones de rabia' },
          { code: 'AP6', text: 'He sido víctima de comportamientos intimidatorios como empujones, bloqueos o invasiones de mi espacio personal' }
        ],
        questionText: '¿En general, ¿Con qué frecuencia...?',
        answersType: 'matrix',
        answers: 'everyone', // 'everyone-but-self`
        type: 'matrix'
      },
      {
        subdimension: 'Victimización',
        code: 'at3',
        questionText: 'El acoso laboral se entiende como toda conducta que constituya agresión u hostigamiento ejercida por el empleador o por uno o más trabajadores, en contra de otro u otros trabajadores, por cualquier medio, ya sea que se manifieste una sola vez o de manera reiterada, y que tenga como resultado para el o los afectados su menoscabo, maltrato o humillación, o bien que amenace o perjudique su situación laboral o sus oportunidades en el empleo. Según esta definición, ¿has sido víctima de acoso laboral durante los últimos seis meses?',
        answersType: 'provided',
        answers: [
          {
            code: 'AO17',
            text: 'No'
          },
          {
            code: 'AF01',
            text: 'Si, solo una vez'
          },
          {
            code: 'AF02',
            text: 'Si, algunas veces'
          },
          {
            code: 'AF03',
            text: 'Si, frecuentemente'
          },
          {
            code: 'AF04',
            text: 'Si, muy frecuentemente'
          }
        ],
        type: 'radio'

      }

    ]
  }
]

export default surveyQuestions
