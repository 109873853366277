import { Link, useParams } from 'react-router-dom'
import { useCurrentProfile } from '../../services/api'
import classes from './membership.module.scss'
import { ProfileCard } from '../../components/profile-card.component.jsx'

export function UserMembershipPage () {
  const currentProfile = useCurrentProfile()
  const { membershipId } = useParams()
  const membership = currentProfile.memberships.find(membership => membership.id === membershipId)

  const isManager = membership.membershipPolicies.map(policy => policy.config.name).includes('manager')

  return (
    <>
      <header>
        <h3>Inicio</h3>
      </header>
      <section className={classes.boxes}>
        <section>
          <ProfileCard profile={currentProfile} membership={membership} />
        </section>

        <section className={classes.links}>
          <h2>accesos directos</h2>
          <nav>
            <ul>
              <li><Link to='surveys'>mis encuestas</Link></li>
              {isManager && <li><Link to='company'>mi compañia</Link></li>}
            </ul>
          </nav>
        </section>
      </section>

    </>
  )
}
