import React from 'react'
import CreateMembershipForm from './create-membership-form'
import classes from './create-membership-form.module.scss'

const errorCodes = {
  'membership/create/P2002': 'El usuario ya existe',
  'membership/create/auth/invalid-password': 'La contraseña debe tener al menos 6 caracteres'
}

const CreateMembershipWithResultsForm = ({ isLoading, trigger, data, error }) => {
  const alert = error && <div role='alert' className={classes.alert}><p>Error: <span>{errorCodes[error.code]}</span></p></div>

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        {alert}
        {isLoading
          ? <p>Creando...</p>
          : <CreateMembershipForm
              createUser={trigger}
            />}
      </div>
      {
        data?.person && (
          <div className={[classes.choice, classes.client].join(' ')}>
            <h2>Usuario creado con éxito</h2>
            <p>Nombre: {data.person.name}</p>
            <p>Correo: {data.person.email}</p>
          </div>
        )
      }
    </div>
  )
}

export default CreateMembershipWithResultsForm
