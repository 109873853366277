const RadioAnswers = ({ currentResponse, question }) => {
  if (!currentResponse || !question) return <p>A:sin respuesta </p>
  const answertText = question.answers.find(answer => answer.code === currentResponse.content.answerId).text
  return (
    <div>
      <p className='text-user-primary-600'>{answertText}</p>
    </div>
  )
}

export default RadioAnswers
