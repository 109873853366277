import { redirect } from 'react-router-dom'
import { signOut, initializeAuth } from './services/auth.js'
import { RootLayer } from './pages/layers/root-layer.jsx'
import { AuthenticatedLayer } from './pages/layers/authenticated-layer.jsx'
import { ProfiledLayer } from './pages/layers/profiled-layer.jsx'
import { CurrentMembershipLayer } from './pages/layers/current-membership-layer.jsx'
import { AdminLayout } from './pages/layouts/admin.jsx'
import { LoginPage } from './pages/login.jsx'
import { LoginRecoveryPage } from './pages/login/recovery.jsx'
import { LoginRecoveryResetPage } from './pages/login/recovery/reset.jsx'
import { RootLobbyPage } from './pages/root-lobby.jsx'
import { UserPage } from './pages/user.page.jsx'
import { UserMembershipPage } from './pages/user/membership.page.jsx'
import { AdminCompaniesPage } from './pages/admin/companies.page.jsx'
import { AdminPeoplePage } from './pages/admin/people.page.jsx'
import { AdminDashboardPage } from './pages/admin/dashboard.jsx'
import { NotFound } from './components/not-found.jsx'
import { AdminCompanyPage } from './pages/admin/company.page.jsx'
import { AdminNewCompanyPage } from './pages/admin/company-new.page.jsx'
import { MembershipSurveysPage } from './pages/user/membership/surveys.page.jsx'
import { MembershipSurveyPage } from './pages/user/membership/survey.page.jsx'
import { MembershipSurveyResponsesPage } from './pages/user/membership/survey-responses.page.jsx'
import { UserMembershipsPage } from './pages/user/memberships.page.jsx'
import { SessionLayout } from './pages/layouts/session.jsx'
import { UserLayout } from './pages/layouts/user.jsx'
import { AdminCompanyNewMembershipPage } from './pages/admin/membership-new.page.jsx'
import MemebershipsPage from './pages/admin/memberships.page.jsx'
import { AdminCompanySurveyEventsPage } from './pages/admin/company/survey-events.jsx'
import { AdminCompanyNewSurveyEventPage } from './pages/admin/company/survey-events/new.jsx'
import { MembershipCompanyNewSurveyEventPage } from './pages/user/membership/company/survey-events/new.jsx'
import { AdminCompanySurveyEventPage } from './pages/admin/company/survey-event.jsx'
import { AdminNewPeoplePage } from './pages/admin/people-new.page.jsx'
import Company from './pages/user/membership/company/company.page.jsx'
import { MembershipCompanySurveyEventsPage } from './pages/user/membership/company/survey-events/survey-events.jsx'
import { MembershipCompanySurveyEventPage } from './pages/user/membership/company/survey-events/survey-event.jsx'
import { CompanyMembershipsPage } from './pages/user/membership/company/collaborators/collaborators-page.jsx'
import { CompanyNewMembershipPage } from './pages/user/membership/company/collaborators/new.jsx'
import { CompanyManagerLayer } from './pages/layers/company-manager-layer.jsx'

// TODO: refactor this action a component with old style hook
const rootLayerLoader = async () => {
  await initializeAuth()
  return {}
}

// TODO: check if handle.crumb could be refactored or at least have a more complex use case
const routes = [
  {
    path: '/',
    element: <RootLayer />,
    loader: rootLayerLoader,
    children: [
      {
        element: <AuthenticatedLayer />,
        handle: { breadcrumb: true },
        children: [
          {
            element: <ProfiledLayer />,
            children: [
              {
                index: true,
                element: <RootLobbyPage />
              },
              {
                path: 'admin',
                element: <AdminLayout />,
                handle: { breadcrumb: true },
                children: [
                  {
                    index: true,
                    element: <AdminDashboardPage />
                  },
                  {
                    path: 'companies',
                    handle: { breadcrumb: true },
                    children: [
                      {
                        index: true,
                        element: <AdminCompaniesPage />
                      },
                      {
                        path: 'new',
                        element: <AdminNewCompanyPage />,
                        handle: { breadcrumb: true }
                      },
                      {
                        path: ':companyId',
                        handle: { breadcrumb: { limit: 8 } },
                        children: [
                          {
                            index: true,
                            element: <AdminCompanyPage />
                          },
                          {
                            path: 'memberships',

                            handle: { breadcrumb: true },
                            children: [
                              {
                                index: true,
                                element: <MemebershipsPage />
                              },
                              {
                                path: 'new',
                                element: <AdminCompanyNewMembershipPage />,
                                handle: { breadcrumb: true }
                              }
                            ]
                          },
                          {
                            path: 'survey_events',
                            handle: { breadcrumb: true },
                            children: [
                              {
                                index: true,
                                element: <AdminCompanySurveyEventsPage />
                              },
                              {
                                path: 'new',
                                element: <AdminCompanyNewSurveyEventPage />,
                                handle: { breadcrumb: true }
                              },
                              {
                                path: ':surveyEventId',
                                element: <AdminCompanySurveyEventPage />,
                                handle: { breadcrumb: true }
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'people',
                    handle: { breadcrumb: true },
                    children: [
                      {
                        index: true,
                        element: <AdminPeoplePage />
                      },
                      {
                        path: 'new',
                        element: <AdminNewPeoplePage />,
                        handle: { breadcrumb: true }
                      }
                    ]
                  },
                  {
                    path: '*',
                    element: <NotFound name='/admin/*' />
                  }
                ]
              },
              {
                path: 'user',
                element: <UserLayout />,
                handle: { breadcrumb: true },
                children: [
                  {
                    index: true,
                    element: <UserPage />
                  },
                  {
                    path: 'memberships',
                    handle: { breadcrumb: true },
                    children: [
                      {
                        index: true,
                        element: <UserMembershipsPage />
                      },
                      {
                        path: ':membershipId',
                        element: <CurrentMembershipLayer />,
                        handle: { breadcrumb: { limit: 8, membershipId: true } },
                        children: [
                          {
                            index: true,
                            handle: { breadcrumb: true },
                            element: <UserMembershipPage />
                          },
                          {
                            path: 'company',
                            element: <CompanyManagerLayer />,
                            handle: { breadcrumb: true },
                            children: [
                              {
                                index: true,
                                element: <Company />
                              },
                              {
                                path: 'survey_events',
                                handle: { breadcrumb: true },
                                children: [
                                  {
                                    index: true,
                                    element: <MembershipCompanySurveyEventsPage />
                                  },
                                  {
                                    path: 'new',
                                    element: <MembershipCompanyNewSurveyEventPage />,
                                    handle: { breadcrumb: true }
                                  },
                                  {
                                    path: ':surveyEventId',
                                    element: <MembershipCompanySurveyEventPage />,
                                    handle: { breadcrumb: true }
                                  }
                                ]
                              },
                              {
                                path: 'collaborators',
                                handle: { breadcrumb: true },
                                children: [
                                  {
                                    index: true,
                                    element: <CompanyMembershipsPage />
                                  },
                                  {
                                    path: 'new',
                                    element: <CompanyNewMembershipPage />,
                                    handle: { breadcrumb: true }
                                  }
                                ]

                              }
                            ]

                          },

                          {
                            path: 'surveys',
                            handle: { breadcrumb: true },
                            children: [
                              {
                                index: true,
                                element: <MembershipSurveysPage />
                              },
                              {
                                path: ':surveyEventId',
                                handle: { breadcrumb: { limit: 8 } },
                                children: [
                                  {
                                    index: true,
                                    element: <MembershipSurveyPage />
                                  },
                                  {
                                    path: 'survey_responses',
                                    element: <MembershipSurveyResponsesPage />,
                                    handle: { breadcrumb: true }
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '*',
                    element: <NotFound name='/user/*' />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound name='/*' />
              }
            ]
          }
        ]
      },
      {
        element: <SessionLayout />,
        children: [
          {
            path: 'login',
            children: [
              {
                index: true,
                element: <LoginPage />
              },
              {
                path: 'recovery',
                children: [
                  {
                    index: true,
                    element: <LoginRecoveryPage />
                  },
                  {
                    path: 'reset',
                    element: <LoginRecoveryResetPage />
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <NotFound name='/*' />
      }
    ]
  },
  {
    path: '/logout',
    async loader () {
      await signOut()
      return redirect('/login', { replace: true })
    }
  }
]

export default routes
