import SubdimensionsGroups from './subdimensions-groups'
import SurveyTabGroup from './survey-tab-group'
import { useSearchParams } from 'react-router-dom'

const DimensionsGroups = ({ groups, onAfterLastGroup, name }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const groupTabMap = groups.reduce((acc, { code }, index) => {
    acc[code] = index
    return acc
  }, {})

  const tabGroupMap = groups.reduce((acc, { code }, index) => {
    acc[index] = code
    return acc
  }, {})

  const selectedGroup = groupTabMap[searchParams.get(name)] || 0
  const groupsLength = groups.length
  const isLastGroup = selectedGroup === groupsLength - 1
  const onAfterLastSubgroup = () => {
    if (isLastGroup) {
      onAfterLastGroup()
    } else {
      const group = tabGroupMap[(selectedGroup + 1) % groupsLength]
      setSearchParams({ [name]: group })
    }
  }

  const onBackFirstSubgroup = () => {
    // TODO: refactor without using subdimension knowledge
    const group = tabGroupMap[(selectedGroup - 1) % groupsLength]
    const previousGroupLast = groups.find(g => g.code === group).questions.slice(-1)[0].code
    setSearchParams({ ...searchParams, [name]: group, subdimension: previousGroupLast })
  }
  const list = groups.map(({ code, dimension }) => ({ key: code, element: dimension }))
  const panels = groups.map(({ code, questions: subgroups }) => (
    {
      key: code,
      element: (
        <SubdimensionsGroups
          groups={subgroups}
          onAfterLastGroup={onAfterLastSubgroup}
          isLastDimension={isLastGroup}
          name='subdimension'
          selectedDimension={selectedGroup}
          onBackFirstGroup={onBackFirstSubgroup}
        />
      )
    }
  ))

  return (
    <SurveyTabGroup
      list={list}
      panels={panels}
      selectedIndex={selectedGroup}
    />
  )
}

export default DimensionsGroups
