import { Link, useLocation, useMatches } from 'react-router-dom'
import { useCurrentProfile } from '../services/api'
import { HomeIcon } from '@heroicons/react/24/outline'
import classes from './breadcrumbs.module.scss'

export function Breadcrumbs () {
  const matches = useMatches()
  const location = useLocation()
  const currentProfile = useCurrentProfile()

  const toText = ({ pathname, handle }) => {
    if (pathname === '/') return <HomeIcon aria-hidden='true' className={classes.icon} />
    const fullText = pathname.split('/').pop()
    if (handle?.breadcrumb?.limit) return fullText.slice(0, handle.breadcrumb.limit) + '\u2026'
    else return fullText
  }
  const breadcrumbs = matches
    .filter(match => Boolean(match.handle?.breadcrumb))
    .map(match => ({ ...match, text: toText(match), isCurrent: location.pathname === match.pathname }))
    .filter(breadcrumb => {
      switch (breadcrumb.text) {
        case 'admin':
        case 'user':
          return currentProfile.isAdmin && currentProfile.isUser
        case 'memberships':
          return (currentProfile.isAdmin && breadcrumb.pathname.startsWith('/admin')) || ((currentProfile.isUser && breadcrumb.pathname.startsWith('/user')) && currentProfile.memberships.length > 1)
        default:
          if (breadcrumb.handle?.breadcrumb?.membershipId) return currentProfile.memberships.length > 1
          return true
      }
    })

  return (
    <ol role='list' className={classes.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, index) => {
        const separator = index < breadcrumbs.length - 1 ? <span className={classes.separator}>/</span> : null
        return (
          <li key={index} className={classes.breadcrumb}>
            {
              breadcrumb.isCurrent
                ? <span>{toText(breadcrumb)}</span>
                : <><Link to={breadcrumb.pathname}>{toText(breadcrumb)}</Link>{separator}</>
            }
          </li>
        )
      })}
    </ol>
  )
}
