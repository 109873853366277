import { Outlet } from 'react-router-dom'
import { useAuthFetch, CurrentProfileContext } from '../../services/api.js'

export function ProfiledLayer () {
  const { data, error, isLoading } = useAuthFetch('/api/me')
  if (isLoading) return <p>Loading User Info...</p>
  if (error) return <p>Error loading user info: {error.message}</p>
  if (!data) return <p>Initializing...</p>
  const currentProfile = {
    ...data,
    isAdmin: data?.personPolicies?.length > 0, // TODO: check for admin policies properly
    isUser: data?.memberships?.length > 0
  }
  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <Outlet />
    </CurrentProfileContext.Provider>
  )
}
