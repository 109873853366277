import { Link } from 'react-router-dom'
import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import AccurateLogo from '../assets/favicon/white_1.svg'

export function AuthenticatedSiteHeader ({ ...props }) {
  // TODO: refactor this classes to a css module
  return (
    <Disclosure as='nav' className='banner' {...props}>
      <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
        <div className='relative flex h-16 items-center justify-between'>
          <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
            <div className='flex flex-shrink-0 items-center'>
              <img alt='brave up corp' src={AccurateLogo} className='h-12 w-auto' />
            </div>
          </div>
          <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
            <Menu as='div' className='relative ml-3'>
              <MenuButton className='relative flex text-cloud-white-500 text-sm focus:outline-none focus:ring-2 focus:ring-cloud-white-500 focus:ring-offset-2 focus:ring-offset-cloud-white-500'>
                <span className='absolute -inset-1.5' />
                <span className='sr-only'>abrir menú de usuario</span>
                <UserIcon aria-hidden='true' className='h-8 w-8' />
              </MenuButton>
              <MenuItems
                as='nav'
                transition
                className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-cloud-white-500 py-1 shadow-lg ring-1 ring-cloud-white-500 ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
              >
                <MenuItem>
                  <Link to='/logout' className='block px-4 py-2 text-sm'>Cerrar sesión</Link>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>
    </Disclosure>
  )
}
