import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SurveyEventContext } from '../../../contexts/survey-event'
import { Field, RadioGroup } from '@headlessui/react'

const ConsentQuestion = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { createSurveyEventResponse } = useContext(SurveyEventContext)
  const [consent, setConsent] = useState(null)
  const handleConsent = (e) => {
    setConsent(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    createSurveyEventResponse({ content: { consent } })
      .then(() => {
        if (consent === 'no') {
          setConsent(null)
          navigate('..')
        } else {
          navigate(location.pathname, { replace: true })
        }
      })
      .catch(console.error)
  }

  return (
    <div className='w-full border border-gray-300 shadow-lg p-4 rounded-md flex flex-col gap-2'>
      <h4>Consentimiento</h4>
      <p>¿Aceptas participar voluntariamente en esta encuesta y permitir que tus respuestas sean utilizadas de manera confidencial para mejorar la convivencia laboral?</p>
      <p>Tu participación en esta encuesta es fundamental para nuestra organización, ya que nos permitirá comprender mejor las dinámicas laborales y tomar medidas para mejorar el ambiente de trabajo. Te agradecemos de antemano por tu tiempo y honestidad. Ten en cuenta que todas las respuestas serán tratadas con la más estricta confidencialidad.</p>
      <div>
        <form onSubmit={handleSubmit} className='flex flex-col gap-2 mt-2'>
          <RadioGroup value={consent} onChange={handleConsent}>
            <Field className='flex gap-1 items-center'>
              <input onChange={handleConsent} type='radio' name='no-consent' value='yes' />
              <label>Si quiero participar</label>
            </Field>
          </RadioGroup>
          <RadioGroup value={consent} onChange={handleConsent}>
            <Field className='flex gap-1 items-center'>
              <input onChange={handleConsent} type='radio' name='no-consent' value='no' />
              <label>No quiero participar</label>
            </Field>
          </RadioGroup>
          <div className='flex gap-1 items-center w-full justify-end'>
            <button type='submit' className='bg-user-primary-800 hover:bg-user-primary-600 disabled:bg-user-primary-300' disabled={!consent}>
              enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ConsentQuestion
