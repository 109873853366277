import { NavLink } from 'react-router-dom'

export function SessionsNav () {
  return (
    <nav>
      <ul>
        <li><NavLink to='/login' end>ingresa con tu cuenta</NavLink></li>
        <li><NavLink to='/login/recovery' end>¿olvidaste la contraseña?</NavLink></li>
      </ul>
    </nav>
  )
}
