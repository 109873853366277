// TODO: refactor module scss to reuse in other pages
import classes from './people-new-page.module.scss'
import { useApiCreate } from '../../services/api'
import { CreateAdminForm } from '../../components/forms/create-admin.form'

export function AdminNewPeoplePage () {
  const { data, trigger: createAdmin, isLoading, error } = useApiCreate('/api/admin/people')
  let alert
  if (error) {
    alert = error && <div role='alert' className={classes.alert}><p>Error creating admin</p></div>
  }
  return (
    <>
      <header>
        <h3>Crear nuevo admin</h3>
      </header>
      <section>
        <div className={classes.container}>
          <div className={classes.formContainer}>
            {alert}
            {isLoading
              ? <p>Creando admin...</p>
              : <CreateAdminForm
                  createAdmin={createAdmin}
                />}

          </div>
          {
            data?.person && (
              <div className={[classes.choice, classes.client].join(' ')}>
                <h2>Admin creado con éxito</h2>
                <p>Nombre: {data.person.name}</p>
                <p>Correo: {data.person.email}</p>
                {data.memberships && (
                  <p>Miembro en: {data?.memberships[0]?.companyId}</p>
                )}
              </div>
            )
          }
        </div>
      </section>
    </>
  )
}
