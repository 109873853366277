import { Outlet } from 'react-router-dom'
import logoVerticalAccurate from '../../assets/png-logo/blue_2.png'
import classes from './session.module.scss'
import { SessionsNav } from '../../components/login/sessions-nav'

export function SessionLayout () {
  return (
    <main className={classes.main} data-theme='client'>
      <header>
        <img
          alt='Accurate logo'
          src={logoVerticalAccurate}
        />
        <hr />
      </header>

      <article>
        <Outlet />
      </article>

      <footer>
        <SessionsNav />
      </footer>
    </main>
  )
}
