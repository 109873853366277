import { Link } from 'react-router-dom'

export function MembershipsNav ({ memberships }) {
  return (
    <nav>
      <ul>
        {memberships.map(membership => (
          <li key={membership.id}>
            <Link to={membership.id}>{membership.company.name}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
