import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { CurrentMembershipContext } from '../../../../../contexts/current-membership'
import { useAuthFetch } from '../../../../../services/api'
import CollaboratorsTable from '../../../../../components/tables/collaborators-table'

export function CompanyMembershipsPage () {
  const { companyId } = useContext(CurrentMembershipContext)
  const { data: users, isLoading, error } = useAuthFetch(`/api/companies/${companyId}/memberships`)

  if (isLoading) { return <p>Loading...</p> }
  if (!companyId) { return <p>No company id</p> }
  if (error) { return <p>{error.message}</p> }

  return (
    <>
      <header>
        <h3>Administrador de usuarios</h3>
      </header>
      <section>
        <div className='flex justify-end w-full'>
          <Link className='mb-4' to='new'>Nuevo usuario</Link>
        </div>
        {users && <CollaboratorsTable memberships={users} />}
      </section>
    </>
  )
}
