import { Navigate } from 'react-router-dom'
import { useCurrentProfile } from '../services/api'

export function UserPage () {
  const { memberships } = useCurrentProfile()

  if (!memberships) return <p>TODO: handle when memberships is not present in currentProfile</p>
  if (memberships.length === 0) return <p>TODO: handle case no memberships</p>
  if (memberships.length > 1) return <Navigate to='memberships' replace />

  const [membership] = memberships
  return <Navigate to={`memberships/${membership.id}`} replace />
}
