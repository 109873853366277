import { Link } from 'react-router-dom'

const CollaboratorsTable = ({ memberships }) => {
  if (!memberships || !memberships.length) return null

  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Correo electrónico</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {
          memberships.map(membership => (
            <tr key={membership.id}>
              <td>{membership.person.name}</td>
              <td>{membership.person.email}</td>
              <td>
                <ul>
                  <li>
                    <Link>Resetear contraseña</Link>
                  </li>
                </ul>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default CollaboratorsTable
