import { useContext } from 'react'
import { SurveyEventContext } from '../../../contexts/survey-event.jsx'
import QuestionFactory from '../question-factory.jsx'
import { SurveyResponsesContext } from '../../../contexts/survey-event-provider.jsx'

const Subdimension = ({ subdimension, handleOnNext, isLastDimension, onBack, isFirstDimnesionAndSubdimension }) => {
  const goBackButton = (
    isFirstDimnesionAndSubdimension
      ? null
      : (
        <button type='button' onClick={onBack}>
          Volver
        </button>
        )
  )
  const { populationLabelById, setQuestionContext, questionContext } = useContext(SurveyEventContext)
  const { data: responses } = useContext(SurveyResponsesContext)
  if (subdimension.dependency) {
    const currentResponse = responses.filter(response => response.content.questionId === subdimension.dependency)
      .sort((a, b) => a.createdAt < b.createdAt)
      .slice(-1)
      .pop()
    if (!currentResponse) return null
    switch (subdimension.type) {
      case 'radio': {
        const subQuestions = questionContext[subdimension.dependency].map((dependencyCode) => {
          return (
            <div key={dependencyCode.code}>
              <QuestionFactory
                key={dependencyCode}
                questionAnswer={dependencyCode}
                question={{ ...subdimension, questionText: populationLabelById(dependencyCode.code) }}
                setQuestionContext={setQuestionContext}
                onSubmitSuccess={handleOnNext}
                isLastDimension={isLastDimension}
              />
              {goBackButton}
            </div>
          )
        })
        return (
          <div key={subdimension.code}>
            <h4>{subdimension.questionText}</h4>
            {subQuestions}
          </div>
        )
      }
      case 'matrix': {
        const subquestions = currentResponse.content.answers.map(code => {
          return { code, text: populationLabelById(code) }
        })
        return (
          <>
            <QuestionFactory
              key={subdimension.code}
              question={{ ...subdimension, subquestions }}
              setQuestionContext={setQuestionContext}
              onSubmitSuccess={handleOnNext}
              isLastDimension={isLastDimension}
            />
            {goBackButton}
          </>
        )
      }
      default:
        console.warn('Unknown subdimension type', subdimension.type)
        return null
    }
  } else {
    return (
      <>
        <QuestionFactory
          key={subdimension.code}
          question={subdimension}
          setQuestionContext={setQuestionContext}
          onSubmitSuccess={handleOnNext}
          isLastDimension={isLastDimension}
        />
        {goBackButton}
      </>

    )
  }
}

export default Subdimension
