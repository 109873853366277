import { Link } from 'react-router-dom'
import { PersonTable } from '../../components/people-list.component.jsx'
import { useAuthFetch } from '../../services/api'

const genericErrorMessage = 'Error loading people'

export function AdminPeoplePage () {
  const { data, error, loading } = useAuthFetch('/api/admin/people')
  if (loading) return <p>Loading people...</p>
  if (error) return <p>{genericErrorMessage}</p>
  if (!data) return <p>Initializing...</p>

  return (
    <>
      <header>
        <h2>Lista de Admins</h2>
      </header>
      <section>
        <div className='flex justify-end w-full'>
          <Link className='mb-4' to='new'>Nuevo Admin</Link>
        </div>
        <PersonTable people={data} />
      </section>
    </>
  )
}
