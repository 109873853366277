import { createContext } from 'react'
import { useSelectedResponses } from '../hooks/useSelectedResponses'

export const AddResponsesContext = createContext()

const AddResponsesContextProvider = ({ children }) => {
  const { mutate } = useSelectedResponses()

  return (
    <AddResponsesContext.Provider value={{ setAddResponses: mutate }}>
      {children}
    </AddResponsesContext.Provider>
  )
}

export default AddResponsesContextProvider
