import { Link, useParams } from 'react-router-dom'
import { useAuthFetch } from '../../../services/api'

export function MembershipSurveysPage () {
  // TODO: remove ?
  // TODO: change in redenring component when have more dan 1 survey
  const { membershipId } = useParams()
  const { data, isLoading, error } = useAuthFetch(`/api/memberships/${membershipId}/survey_events`)
  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>
  if (!data) return <p>Initializing...</p>

  const handleReviewSurveyStatus = (survey) => {
    const { content: { closesAt } } = survey
    const today = new Date().toISOString()
    if (today > closesAt) {
      return <p className='text-rose-500 font-semibold'>Cerrada</p>
    } else if (survey.surveyEventParticipantStatuses[0]?.content.status === 'finished') {
      return <p className='text-ripe-lemon-600 font-semibold'>Terminada</p>
    }
    return <Link to={`${survey.id}`}>Contestar</Link>
  }

  return (
    <>
      <header>
        <h3>Mis Encuestas</h3>
      </header>
      <section>
        <table>
          <thead>
            <tr>
              <th>Encuestas</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
            data.map(survey => (
              <tr key={survey.id}>
                <td>Encuesta Laboral</td>
                <td>
                  <ul className='space-y-2'>
                    <li>
                      {handleReviewSurveyStatus(survey)}
                    </li>
                    <li><Link to={`${survey.id}/survey_responses`}>Ver respuestas</Link></li>
                  </ul>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </section>
    </>
  )
}
