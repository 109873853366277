import { useContext } from 'react'
import { SurveyEventContext } from '../../../contexts/survey-event'

const MultipleSelectAnswers = ({ currentResponse }) => {
  const { populationLabelById } = useContext(SurveyEventContext)
  if (!currentResponse) return <p>A: sin respuesta </p>
  const { answers } = currentResponse.content
  return (
    <ul>
      {
        answers?.map(
          (answer) =>
            <li className='text-user-primary-600' key={answer}>
              A: {populationLabelById(answer)}
            </li>
        )
      }
    </ul>
  )
}

export default MultipleSelectAnswers
