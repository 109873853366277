import { Link } from 'react-router-dom'

export default function CompanyList ({ companies }) {
  return (
    <ul>
      {companies.map(company => (
        <li key={company.id}>
          <Link to={company.id}>{company.name}</Link>
        </li>
      ))}
    </ul>
  )
}

export function CompanyTable ({ companies }) {
  return (
    <table>
      <thead>
        <tr>
          {/* <th>id</th> */}
          <th>Organizaciones</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {companies.map(company => (
          <tr key={company.id}>
            {/* <td>{company.id}</td> */}
            <td>{company.name}</td>
            <td>
              <ul>
                <li><Link to={`${company.id}`}>ver más</Link></li>
                <li><Link to={`${company.id}/memberships`}>colaboradores</Link></li>
                <li><Link to={`${company.id}/survey_events`}>encuestas</Link></li>
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
