import { useState } from 'react'
import { Button, Field, Label, RadioGroup } from '@headlessui/react'
import { RequiredRadio } from '../../forms/required-radio.jsx'

const RadioQuestion = ({ question, currentResponse, createSurveyEventResponse, onSubmitSuccess, isLastDimension }) => {
  const [selected, setSelected] = useState(currentResponse?.content.answerId)
  const { answers, code } = question
  const onSubmit = event => {
    event.preventDefault()
    if (selected !== currentResponse?.content.answerId) {
      const data = { content: { questionId: question.code, answerId: selected } }
      createSurveyEventResponse(data)
        .then(() => { onSubmitSuccess() })
    } else {
      onSubmitSuccess()
    }
  }

  const inputs = (
    <RadioGroup value={selected || ''} onChange={setSelected} aria-label={question.subdimension}>
      {answers.map(({ code, text }) => (
        <Field key={code} className='flex items-center gap-2'>
          <RequiredRadio value={code} name={`option-${code}`} selected={selected} />
          <Label>{text}</Label>
        </Field>
      ))}
    </RadioGroup>
  )
  return (
    <form id={code} onSubmit={onSubmit}>
      {inputs}
      <div className='m-4 flex  justify-end'>
        {isLastDimension ? <Button type='submit'>Finalizar</Button> : <Button type='submit'>Siguiente</Button>}
      </div>
    </form>
  )
}

export default RadioQuestion
