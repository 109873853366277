import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import config from '../../config/firebase.js'

const app = initializeApp(config.app.options)
const auth = getAuth(app)
if (config.emulator) {
  const { emulator: { url, options } } = config
  connectAuthEmulator(auth, url, options)
}

export { auth }
