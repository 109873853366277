import { createContext, useContext, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Field, Fieldset, Input, Label } from '@headlessui/react'
import { useCurrentUser } from '../../../hooks/use-current-user'
import { useConfirmPasswordReset, useVerifyPasswordResetCode } from '../../../services/auth'
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline'

function AlreadyLoggedIn ({ currentUser }) {
  return (
    <>
      <h1>bienvenido <span className='current-user user-email'>{currentUser.email}</span></h1>
      <p className='mt-10 text-center text-sm text-gunsmoke-500'>
        ¿quieres acceder con otra cuenta?{' '}<Link to='/logout' className='font-semibold leading-6 text-java-600 hover:text-java-500'>cerrar sessión</Link>
      </p>
    </>
  )
}

function LoginRecoveryResetForm ({ ...props }) {
  const { email, oobCode } = useContext(LoginRecoveryResetContext)
  const { data, isMutating, error, trigger } = useConfirmPasswordReset()

  const handleOnSubmit = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const oobCode = formData.get('oobCode')
    const newPassword = formData.get('new-password')
    const confirmNewPassword = formData.get('confirm-new-password')
    trigger(oobCode, newPassword, confirmNewPassword)
  }

  if (data) {
    return (
      <div role='alert' data-alert='success'>
        <ShieldCheckIcon role='img' />
        <p>constraseña reestablecida</p>
      </div>
    )
  }

  // TODO: translate error code
  const errorAlert = error && (
    <div role='alert' data-alert='error small'>
      <ShieldExclamationIcon role='img' />
      <p>{error.code}</p>
    </div>
  )

  return (
    <form id='login-reset-form' onSubmit={handleOnSubmit} {...props}>
      <Fieldset disabled={isMutating}>
        {errorAlert}
        <Field hidden>
          <Label>oobCode</Label>
          <Input
            autoComplete='off'
            name='oobCode'
            readOnly
            required
            type='hidden'
            value={oobCode}
          />
        </Field>
        <Field hidden>
          <Label>email</Label>
          <Input
            autoComplete='email username'
            name='email'
            readOnly
            required
            type='email'
            value={email}
          />
        </Field>
        <Field>
          <Label>contraseña</Label>
          <Input
            autoComplete='new-password'
            name='new-password'
            required
            type='password'
          />
        </Field>
        <Field>
          <Label>confirma contraseña</Label>
          <Input
            autoComplete='new-password'
            name='confirm-new-password'
            required
            type='password'
          />
        </Field>
        <Button type='submit'>enviar</Button>
      </Fieldset>
    </form>
  )
}

const LoginRecoveryResetContext = createContext()

const LoginRecoveryResetProvider = ({ oobCode, ...props }) => {
  const { data, isMutating, error, trigger } = useVerifyPasswordResetCode()

  useEffect(() => { trigger(oobCode) }, [trigger, oobCode])

  if (data) {
    return <LoginRecoveryResetContext.Provider value={{ email: data, oobCode }} {...props} />
  }
  if (isMutating) {
    return <p role='progressbar'>verificando...</p>
  }
  if (error) {
    return (
      <div role='alert' data-alert='error'>
        <ShieldExclamationIcon role='img' />
        <p>{error.message}</p>
      </div>
    )
  }
  return null
}

export function LoginRecoveryResetPage () {
  const [searchParams] = useSearchParams()
  const oobCode = searchParams.get('oobCode')
  const currentUser = useCurrentUser()
  if (currentUser) {
    return <AlreadyLoggedIn currentUser={currentUser} />
  }

  return (
    <>
      <h1>reestablece tu contraseña</h1>
      <LoginRecoveryResetProvider oobCode={oobCode}>
        <LoginRecoveryResetForm />
      </LoginRecoveryResetProvider>
    </>
  )
}
