import { createContext, useState } from 'react'
import { SurveyEventContext } from './survey-event.jsx'
import { useApiCreate, useAuthFetch } from '../services/api.js'

export const SurveyResponsesContext = createContext()

export const SurveyResponsesContextProvider = ({ membershipId, surveyEventId, children }) => {
  const { data, error, isLoading, mutate } = useAuthFetch(`/api/memberships/${membershipId}/survey_events/${surveyEventId}/survey_responses`)

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error SurveyResponsesContextProvider: {error.message}</p>

  return (
    <SurveyResponsesContext.Provider value={{ data, mutate }}>
      {children}
    </SurveyResponsesContext.Provider>
  )
}

export const SurveyEventProvider = ({ children, surveyEventId, membershipId }) => {
  const [questionContext, setQuestionContext] = useState({})
  const { data: surveyEvent, isLoading, error } = useAuthFetch(`/api/memberships/${membershipId}/survey_events/${surveyEventId}`)
  const { trigger: createSurveyEventResponse, isLoading: isCreatingSurveyEventResponse } = useApiCreate(`/api/memberships/${membershipId}/survey_events/${surveyEventId}/survey_responses`)

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const population = (surveyEvent.participants.ids).map(id => ({ value: id, label: surveyEvent.participants.metadata[id].person.name }))
  const populationLabelById = id => surveyEvent.participants.metadata[id].person.name

  return (
    <SurveyEventContext.Provider value={{ surveyEvent, population, populationLabelById, setQuestionContext, questionContext, createSurveyEventResponse, isCreatingSurveyEventResponse }}>
      <SurveyResponsesContextProvider membershipId={membershipId} surveyEventId={surveyEventId}>
        {children}
      </SurveyResponsesContextProvider>
    </SurveyEventContext.Provider>
  )
}
