import { Navigate } from 'react-router-dom'
import { useCurrentProfile } from '../../services/api'
import { MembershipsNav } from '../../components/membership-nav.component'

export function UserMembershipsPage () {
  const { memberships } = useCurrentProfile()

  if (!memberships) return <p>TODO: handle memberships not present</p>
  if (memberships.length === 0) return <p>TODO: handle user has no memberships</p>
  if (memberships.length === 1) {
    const [membership] = memberships
    return <Navigate to={`${membership.id}`} replace />
  }
  return (
    <>
      <header>
        <h1>Seleccion de membresía</h1>
      </header>
      <section>
        <MembershipsNav memberships={memberships} />
      </section>
    </>
  )
}
