import React from 'react'
import Subdimension from './subdimension'
import SurveyTabGroup from './survey-tab-group'
import { useSearchParams } from 'react-router-dom'

const SubdimensionsGroups = ({ groups, onAfterLastGroup, isLastDimension, name, onBackFirstGroup, selectedDimension }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const groupTabMap = groups.reduce((acc, { code }, index) => {
    acc[code] = index
    return acc
  }, {})

  const tabGroupMap = groups.reduce((acc, { code }, index) => {
    acc[index] = code
    return acc
  }, {})

  const selectedGroup = groupTabMap[searchParams.get(name)] || 0
  const isFirstDimnesionAndSubdimension = selectedDimension === 0 && selectedGroup === 0
  const handleOnNext = () => {
    const isLastGroup = Number(selectedGroup) >= groups.length - 1
    if (isLastGroup) {
      onAfterLastGroup()
    } else {
      const group = tabGroupMap[(Number(selectedGroup) + 1).toString()]
      const searchParamsValues = Object.fromEntries(searchParams.entries())
      setSearchParams({ ...searchParamsValues, [name]: group })
    }
  }
  const onBack = () => {
    const isFirstGroup = selectedGroup === 0
    if (isFirstGroup) {
      onBackFirstGroup()
    } else {
      const dimension = searchParams.get('dimension')
      const group = tabGroupMap[(Number(selectedGroup) - 1).toString()]
      setSearchParams({ dimension, [name]: group })
    }
  }

  const list = groups.map(({ code, subdimension: group }) => ({ key: code, element: group }))
  const panels = groups.map((group) => (
    {
      key: group.code,
      element: (
        <Subdimension
          key={group.code}
          code={group.code}
          isLastDimension={isLastDimension}
          subdimension={group}
          handleOnNext={handleOnNext}
          onBack={onBack}
          isFirstDimnesionAndSubdimension={isFirstDimnesionAndSubdimension}
        />
      )
    }
  ))

  return (
    <div className='border border-black-300 shadow-md rounded-md mt-4 w-full p-4'>
      <SurveyTabGroup list={list} panels={panels} selectedIndex={selectedGroup} />
    </div>
  )
}

export default SubdimensionsGroups
