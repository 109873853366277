import { Navigate, Outlet, useParams } from 'react-router-dom'
import { CurrentMembershipProvider } from '../../contexts/current-membership-provider.jsx'
import { useCurrentProfile } from '../../services/api.js'

export function CurrentMembershipLayer () {
  const { membershipId } = useParams()
  const currentProfile = useCurrentProfile()

  const membership = currentProfile.memberships.find(membership => membership.id === membershipId)
  if (!membership) {
    return <Navigate to='/' />
  }

  return (
    <CurrentMembershipProvider membershipId={membershipId} companyId={membership.companyId}>
      <Outlet />
    </CurrentMembershipProvider>
  )
}
