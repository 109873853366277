const handleTimeActions = (date) => {
  if (!date) { return 'not set' }
  const currentDate = new Date(date)
  const fullDateTime = currentDate.toLocaleString('es-ES', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  })
  return fullDateTime
}

export { handleTimeActions }
