import { useApiCreate } from '../../../../../services/api'
import CreateMembershipWithResultsForm from '../../../../../components/forms/create-membership-with-results-form'
import { useContext } from 'react'
import { CurrentMembershipContext } from '../../../../../contexts/current-membership'

export function CompanyNewMembershipPage () {
  const { companyId } = useContext(CurrentMembershipContext)
  const { data, isLoading, error, trigger } = useApiCreate(`/api/companies/${companyId}/memberships`)
  return (
    <>
      <header>
        <h2>Crear nuevo usuario</h2>
      </header>
      <section>
        <CreateMembershipWithResultsForm isLoading={isLoading} trigger={trigger} data={data} error={error} />
      </section>
    </>
  )
}
