import { Navigate, Outlet } from 'react-router-dom'
import { CurrentMembershipContext } from '../../contexts/current-membership.jsx'
import { useContext } from 'react'

export function CompanyManagerLayer () {
  const currentMembership = useContext(CurrentMembershipContext)
  const { membershipPolicies } = currentMembership

  const isManager = membershipPolicies.length > 0

  if (isManager) return <Outlet />
  return <Navigate to='/' />
}
