import { useParams } from 'react-router-dom'
import { useAuthFetch, useCurrentProfile } from '../../../../../services/api.js'
import { SurveyEvent } from '../../../../../components/survey-event.jsx'

export function MembershipCompanySurveyEventPage () {
  const { surveyEventId, membershipId } = useParams()
  const currentProfile = useCurrentProfile()
  const membership = currentProfile.memberships.find(membership => membership.id === membershipId)
  const { data, isLoading, error } = useAuthFetch(`/api/companies/${membership.companyId}/survey_events/${surveyEventId}`)

  if (isLoading) { return <p>Loading...</p> }
  if (error) { return <p>{error.message}</p> }

  return (
    <>
      <header>
        <h3>{data.content.title || 'No name'}</h3>
      </header>
      <section>
        <SurveyEvent surveyEvent={data} />
      </section>
    </>
  )
}
