import { createContext, useContext } from 'react'
import config from '../config/api'
import { getToken } from './auth'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { BupError } from '../lib/errors'

const handleResponse = res => {
  if (!res.ok) {
    return res.json().then(data => {
      const options = data?.error || { message: 'Fetch error', code: 'api/fetch/error' }
      throw new BupError(options)
    })
  }
  if (res.status === 204) {
    return null
  }
  return res.json()
}

const getFetcher = (...args) => {
  return getToken()
    .then(token => {
      const [key, options = {}] = args
      const { headers = {}, ...restOptions } = options
      headers.authorization = `Bearer ${token}`
      headers.accept = 'application/json'
      headers['content-type'] = 'application/json'
      const apiBaseUrl = new URL(config.baseUrl)
      const input = new URL(key, apiBaseUrl)
      return fetch(input, { ...restOptions, headers })
        .then(handleResponse)
    })
}

const postFetcher = (...args) => {
  return getToken()
    .then(token => {
      const [key, options = {}] = args
      const { arg: body, headers = {}, ...restOptions } = options
      headers.authorization = `Bearer ${token}`
      headers.accept = 'application/json'
      headers['content-type'] = 'application/json'
      const apiBaseUrl = new URL(config.baseUrl)
      const input = new URL(key, apiBaseUrl)
      const apiOptions = { ...restOptions, headers, method: 'POST' }
      if (body) { apiOptions.body = JSON.stringify(body) }
      return fetch(input, apiOptions)
        .then(handleResponse)
    })
}

const updateFetcher = (...args) => {
  return getToken()
    .then(token => {
      const [key, options = {}] = args
      const { arg: body, headers = {}, ...restOptions } = options
      headers.authorization = `Bearer ${token}`
      headers.accept = 'application/json'
      headers['content-type'] = 'application/json'
      const apiBaseUrl = new URL(config.baseUrl)
      const input = new URL(key, apiBaseUrl)
      const apiOptions = { ...restOptions, headers, method: 'PUT' }
      if (body) { apiOptions.body = JSON.stringify(body) }
      return fetch(input, apiOptions)
        .then(handleResponse)
    })
}

export const useAuthFetch = (path) => {
  return useSWR(path, getFetcher)
}

export const useApiCreate = (path) => {
  return useSWRMutation(path, postFetcher)
}

export const useApiUpdate = (path) => {
  return useSWRMutation(path, updateFetcher)
}

const fetcher = (key, { arg }) => {
  const { url, token, method = 'get' } = key
  const headers = {
    accept: 'application/json',
    'content-type': 'application/json'
  }
  if (token) { headers.authorization = `Bearer ${token}` }
  const options = { headers, method }
  if (arg) { options.body = JSON.stringify(arg) }
  return fetch(url, options)
    .then(handleResponse)
}

export const useApiFetch = (path, options = {}) => {
  const { token } = options
  const url = new URL(path, config.baseUrl)
  return useSWR({ url, token }, fetcher)
}

export const useApiMutation = (path, options = {}) => {
  const { token, method = 'get' } = options
  const url = new URL(path, config.baseUrl)
  return useSWRMutation({ url, token, method }, fetcher)
}

export const CurrentProfileContext = createContext()

export const useCurrentProfile = () => {
  return useContext(CurrentProfileContext)
}
