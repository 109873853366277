import { Button, Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'

const Modal = ({ isOpen, setIsOpen, title, description, paragraph, buttonText, handleAction, classname = 'bg-user-primary-800 hover:bg-user-primary-600' }) => {
  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='relative z-50'>
        <div className='fixed inset-0 flex w-screen items-center border-radius-md justify-center p-4 bg-black bg-opacity-60'>
          <DialogPanel className='max-w-lg space-y-6 border bg-cloud-white-500 p-12 rounded-md shadow'>
            <DialogTitle className='font-bold'>{title}</DialogTitle>
            <Description>{description}</Description>
            <p>{paragraph}</p>
            <div className='flex gap-4 justify-evenly'>
              <Button type='button' onClick={() => setIsOpen(false)}>Cancelar</Button>
              <Button className={classname} type='button' onClick={handleAction}>{buttonText}</Button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  )
}

export default Modal
