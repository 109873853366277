import { useContext, useReducer } from 'react'
import { SurveyEventContext } from '../contexts/survey-event.jsx'

const initialState = []

const selectResponsesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'add_response':
      return [...state, action.payload]
    default:
      return state
  }
}

export const useSelectedResponses = () => {
  const { registerSurveyResponse } = useContext(SurveyEventContext)
  const [state, dispatch] = useReducer(selectResponsesReducer, initialState)

  const mutate = (content) => {
    return (
      registerSurveyResponse({ content })
        .then((response) => {
          dispatch({ type: 'add_response', payload: response })
        })
        .catch((error) => {
          console.log(error)
        })
    )
  }

  return {
    state,
    mutate
  }
}
