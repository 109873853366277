import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useCurrentProfile } from '../../../../services/api'

const Company = () => {
  const currentProfile = useCurrentProfile()
  const { membershipId } = useParams()
  const membership = currentProfile.memberships.find(membership => membership.id === membershipId)

  return (
    <>
      <header>
        <h3>{membership.company.name}</h3>
      </header>
      <section>
        <h4>Administración</h4>
        <ul>
          <li><Link to='collaborators'>personas</Link></li>
          <li><Link to='survey_events'>encuestas</Link></li>
        </ul>
      </section>
    </>
  )
}

export default Company
