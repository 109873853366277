import { useParams } from 'react-router-dom'
import { useAuthFetch } from '../../services/api'
import { Company } from '../../components/company.component'

export function AdminCompanyPage () {
  const { companyId } = useParams()
  const { data: company, error, isLoading } = useAuthFetch(`/api/companies/${companyId}`)

  if (isLoading) return <p>Loading Companies...</p>
  if (error) return <p>Error loading companies: {error.message}</p>
  if (!company) return <p>Initializing...</p>

  return (
    <>
      <header>
        <h3>{company.name}</h3>
      </header>
      <section>
        <Company company={company} />
      </section>
    </>
  )
}
