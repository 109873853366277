import { Radio } from '@headlessui/react'

export function RequiredRadio ({ value, name, selected = false }) {
  return (
    <Radio value={value} className='group flex h-5 w-5 items-center justify-center rounded-full border bg-cloud-white-400 data-[checked]:bg-user-primary-800 hover:border-user-primary-300'>
      {
        selected
          ? <span className='invisible size-2 rounded-full bg-cloud-white-400 group-data-[checked]:visible' />
          : <input type='radio' name={name} required />
      }
    </Radio>
  )
}
