import { UserIcon } from '@heroicons/react/24/outline'

export function ProfileCard ({ profile, membership }) {
  return (
    <div className='card'>
      <div className='photo-wrapper p-2'>
        <UserIcon className='w-32 h-32 rounded-full mx-auto text-gunsmoke-500' />
      </div>
      <div className='p-2'>
        <h2 className='text-center text-xl text-gunsmoke-900 font-medium leading-8'>{profile.name}</h2>
        <div className='text-center text-gunsmoke-500 text-xs font-semibold'>
          {membership && <p>{membership.company.name}</p>}
        </div>
        <dl className='text-xs my-3'>
          <dt className='text-xs font-semibold'>Correo electrónico</dt>
          <dd className='text-xs'>{profile.email}</dd>
        </dl>
      </div>
    </div>
  )
}
