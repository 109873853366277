import { useParams } from 'react-router-dom'
import { useAuthFetch } from '../../../services/api'
import { SurveyEvent } from '../../../components/survey-event.jsx'

export function AdminCompanySurveyEventPage () {
  const { surveyEventId, companyId } = useParams()
  const { data, isLoading, error } = useAuthFetch(`/api/companies/${companyId}/survey_events/${surveyEventId}`)

  if (isLoading) { return <p>Loading...</p> }
  if (error) { return <p>{error.message}</p> }

  return (
    <>
      <header>
        <h3>{data.content.title || 'No name'}</h3>
      </header>
      <section>
        <SurveyEvent surveyEvent={data} />
      </section>
    </>
  )
}
