import { Link } from 'react-router-dom'

export function PersonTable ({ people }) {
  return (
    <table>
      <thead>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>memberships</th>
          <th>actions</th>
        </tr>
      </thead>
      <tbody>
        {people.map(person => (
          <tr key={person.id}>
            <td>{person.id}</td>
            <td>{person.name}</td>
            <td>
              {
                person?.memberships.map(membership => (
                  <p key={membership.companyId}>{membership.company.name}</p>
                  // TODO: meterle css a etiqueta p
                ))
              }
            </td>
            <td>
              <ul>
                <li><Link>Agregar memberships (not working)</Link></li>
                <li><Link>Editar (not working)</Link></li>
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
