import { useParams } from 'react-router-dom'
import { SurveyEventProvider } from '../../../contexts/survey-event-provider.jsx'
import SurveyApp from '../../../components/survey-app.jsx'

export function MembershipSurveyPage () {
  const { membershipId, surveyEventId } = useParams()

  return (
    <>
      <header>
        <h3>encuesta laboral</h3>
      </header>
      <section>
        <SurveyEventProvider membershipId={membershipId} surveyEventId={surveyEventId}>
          <SurveyApp />
        </SurveyEventProvider>
      </section>

    </>

  )
}
