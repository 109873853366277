import { Navigate, useParams } from 'react-router-dom'
import { useApiCreate, useAuthFetch } from '../../../../services/api'
import { SurveyEventForm } from '../../../../components/survey-event-form'

// Admin no tiene membershipId
export function AdminCompanyNewSurveyEventPage () {
  const { companyId } = useParams()
  const { data: memberships } = useAuthFetch(`api/admin/companies/${companyId}/memberships`)
  const { data, isLoading, error, trigger } = useApiCreate(`/api/admin/companies/${companyId}/survey_events`)
  if (data) { return <Navigate to={`../${data.id}`} /> }

  return (
    <>
      <header>
        <h2>Nueva Encuesta</h2>
      </header>
      <section>
        {error && <p>{error.message}</p>}
        <SurveyEventForm onSubmit={trigger} loading={isLoading} memberships={memberships} />
      </section>
    </>
  )
}
