import { useState } from 'react'
import { useApiUpdate, useAuthFetch } from '../services/api'
import Modal from './modal'
import { handleTimeActions } from '../helpers/helpers'

const ParticipantsTable = ({ participants, statuses = [] }) => {
  const statusesByMembershipId = statuses.reduce((acc, status) => {
    acc[status.membershipId] ||= []
    acc[status.membershipId].push(status)
    return acc
  }, {})
  const { ids: participantsId = [], metadata: participantsMetadata } = participants
  const participantsRows = participantsId.map(membershipId => {
    const { [membershipId]: { person } } = participantsMetadata
    return (
      <tr key={membershipId}>
        <td>{person.name}</td>
        <td>{statusesByMembershipId[membershipId]?.map(status => status.content.status).join(', ') || 'in progress'}</td>
      </tr>
    )
  })
  return (
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        {participantsRows}
      </tbody>
    </table>
  )
}

export function SurveyEvent ({ surveyEvent }) {
  const { data } = useAuthFetch(`/api/survey_events/${surveyEvent.id}/survey_responses`)
  const { trigger: mutate } = useApiUpdate(`/api/companies/${surveyEvent.companyId}/survey_events/${surveyEvent.id}`)
  const [isOpen, setIsOpen] = useState(false)
  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'data.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const isSurveyClosed = surveyEvent.content.closesAt <= new Date().toISOString()

  const handleCloseSurvey = () => {
    mutate({
      content: {
        closesAt: new Date().toISOString(),
        opensAt: surveyEvent.content.opensAt,
        title: surveyEvent.content.title
      }
    }).then(() => setIsOpen(false)
    )
  }

  const modalText = {
    title: 'Cerrar encuesta',
    description: 'Estás por cerrar la encuesta.',
    paragraph: 'Una vez cerrada, tus colaboradores no podrán seguir respondiendo la encuesta',
    buttonText: 'Cerrar encuesta'

  }

  return (
    <>
      <section className='grid grid-cols-1 gap-10 w-full bg-cloud-white-500 p-2'>
        <header>
          <dl>
            <dt>hora de apertura</dt>
            <dd>{handleTimeActions(surveyEvent.content.opensAt) || 'not set'}</dd>
            <dt>hora de cierre</dt>
            <dd>{handleTimeActions(surveyEvent.content.closesAt) || 'not set'}</dd>
            {isSurveyClosed ? null : <dd className='cursor-pointer text-rose-500' onClick={() => setIsOpen(true)}>cerrar encuesta</dd>}
            <dt>progreso</dt>
            <dd> {surveyEvent.surveyEventParticipantStatuses.length} / {surveyEvent.participants.ids.length}</dd>
            <dt>estado</dt>
            <dd>{isSurveyClosed ? <span className='text-rose-500'>cerrada</span> : <span className='text-ripe-lemon-500'>en progreso</span>}</dd>
            <dt>respuestas</dt>
            {
             data && data?.length > 0
               ? <dd className='cursor-pointer text-royal-purple-500' onClick={handleDownload}>descargar respuestas</dd>
               : <dd>sin respuestas</dd>
            }
            <dt>resultados</dt>
            <dd>dashboard de resultados</dd>

          </dl>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={modalText.title}
            description={modalText.description}
            paragraph={modalText.paragraph}
            buttonText={modalText.buttonText}
            handleAction={handleCloseSurvey}
            classname='bg-royal-purple-500'
          />
        </header>
        {surveyEvent.participants &&
          <ParticipantsTable
            participants={surveyEvent.participants}
            statuses={surveyEvent.surveyEventParticipantStatuses}
          />}
      </section>
    </>
  )
}
