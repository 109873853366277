import { Navigate, Outlet } from 'react-router-dom'
import { useCurrentProfile } from '../../services/api.js'
import { Breadcrumbs } from '../../components/breadcrumbs.jsx'
import { AuthenticatedSiteHeader } from '../../components/authenticated-site-header.jsx'
import classes from './admin.module.scss'

export function AdminLayout () {
  const currentProfile = useCurrentProfile()
  if (!currentProfile.isAdmin) return <Navigate to='/user' replace />

  return (
    <main className={classes.main} data-theme='admin'>
      <AuthenticatedSiteHeader />
      <article>
        <nav aria-label='breadcrumb'>
          <Breadcrumbs />
        </nav>
        <Outlet />
      </article>
    </main>
  )
}
