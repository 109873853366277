import { Link, useParams } from 'react-router-dom'
import { useAuthFetch } from '../../services/api'
import CollaboratorsTable from '../../components/tables/collaborators-table'

const MemebershipsPage = () => {
  const { companyId } = useParams()
  const { data: memberships } = useAuthFetch(`/api/admin/companies/${companyId}/memberships`)

  return (
    <>
      <header>
        <h3>Lista de Colaboradores</h3>
      </header>
      <section>
        <div className='flex justify-end w-full'>
          <Link className='mb-4' to='new'>Nuevo colaborador</Link>
        </div>
        <CollaboratorsTable memberships={memberships} />
      </section>
    </>
  )
}

export default MemebershipsPage
