import { Link } from 'react-router-dom'
import { useCurrentProfile } from '../../services/api.js'
import { ProfileCard } from '../../components/profile-card.component.jsx'
import classes from './dashboard.module.scss'

export function AdminDashboardPage () {
  const currentProfile = useCurrentProfile()

  return (
    <>
      <header>
        <h3>Inicio</h3>
      </header>
      <section className={classes.boxes}>
        <section>
          <ProfileCard profile={currentProfile} />
        </section>
        <section className={classes.links}>
          <h2>accesos directos</h2>
          <nav>
            <ul>
              <li>
                <Link to='companies'>
                  companies
                </Link>
              </li>
              <li>
                <Link to='people'>
                  admins
                </Link>
              </li>
            </ul>
          </nav>
        </section>
      </section>
    </>
  )
}
