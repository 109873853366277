import { useContext } from 'react'
import { SurveyEventContext } from '../../contexts/survey-event.jsx'
import MatrixQuestion from './answers/matrix.jsx'
import MultipleChoiceQuestion from './answers/multiple-choice.jsx'
import RadioQuestion from './answers/radio.jsx'
import { SurveyResponsesContext } from '../../contexts/survey-event-provider.jsx'
import { CurrentMembershipContext } from '../../contexts/current-membership.jsx'

const QuestionFactory = ({ question, setQuestionContext, questionAnswer, onSubmitSuccess, isLastDimension }) => {
  const { population, codex, createSurveyEventResponse } = useContext(SurveyEventContext)
  const { data: responses } = useContext(SurveyResponsesContext)
  const currentMembership = useContext(CurrentMembershipContext)
  const { type, answersType, answers } = question
  let consolidatedAnswers = answers; let staticType = false
  if (answersType === 'dynamic') {
    const { answers } = question
    switch (answers) {
      case 'everyone':
        consolidatedAnswers = population
        break
      case 'static':
        consolidatedAnswers = population
        staticType = true
        break
      case 'everyone-but-self':
        consolidatedAnswers = population.filter(member => member.value !== currentMembership.id)
        break
      default:
        throw new Error(`Unknown Answer Type method \`${answers}\``)
    }
  }
  const consolidateQuestion = { ...question, answers: consolidatedAnswers, type: staticType }
  const currentResponse = responses.filter(response => response.content.questionId === question.code)
    .sort((a, b) => a.createdAt < b.createdAt)
    .slice(-1)
    .pop()

  let answersForm
  switch (type) {
    case 'radio':
      answersForm = (
        <RadioQuestion
          question={consolidateQuestion}
          questionAnswer={questionAnswer}
          codex={codex}
          createSurveyEventResponse={createSurveyEventResponse}
          onSubmitSuccess={onSubmitSuccess}
          isLastDimension={isLastDimension}
          currentResponse={currentResponse}
        />
      )
      break
    case 'multiple-choice':
      answersForm = (
        <MultipleChoiceQuestion
          question={consolidateQuestion}
          setQuestionContext={setQuestionContext}
          createSurveyEventResponse={createSurveyEventResponse}
          onSubmitSuccess={onSubmitSuccess}
          currentResponse={currentResponse}
        />
      )
      break
    case 'matrix':
      answersForm = (
        <MatrixQuestion
          question={question}
          createSurveyEventResponse={createSurveyEventResponse}
          onSubmitSuccess={onSubmitSuccess}
          currentResponse={currentResponse}
        />
      )
      break
    default:
      throw new Error(`Unknown Question Type method \`${type}\``)
  }
  return (
    <section className={`question-group-${type}`}>
      <header className='question-group-header mt-4 mb-4'>
        {/* <h3 className='question-group-subdimension-title'>{question.subdimension}</h3> */}
        <p className='question-group-questiontext'>{question.questionText}</p>
      </header>
      {answersForm}
    </section>
  )
}

export default QuestionFactory
