import MatrixAnswers from './matrix-answer.component'
import MultipleSelectAnswers from './multiple-select-answer.component'
import RadioAnswers from './radio-answer.component'

const AnswersComponent = ({ question, responses }) => {
  const currentResponse = responses.filter(response => response.content.questionId === question.code)
    .sort((a, b) => a.createdAt < b.createdAt)
    .slice(-1)
    .pop()

  switch (question.type) {
    case 'radio':
      return <RadioAnswers question={question} currentResponse={currentResponse} />
    case 'multiple-choice':
      return <MultipleSelectAnswers currentResponse={currentResponse} />
    case 'matrix':
      return <MatrixAnswers question={question} currentResponse={currentResponse} />
    default:
      return new Error('Invalid question type')
  }
}

export default AnswersComponent
