import { Link, Navigate } from 'react-router-dom'
import { useCurrentProfile } from '../services/api'
import { AuthenticatedSiteHeader } from '../components/authenticated-site-header.jsx'
import classes from './root-lobby.module.scss'

export function RootLobbyPage () {
  const currentProfile = useCurrentProfile()
  console.log('currentProfile', currentProfile)
  if (!currentProfile.isAdmin) return <Navigate to='/user' replace />
  if (!currentProfile.isUser) return <Navigate to='/admin' replace />

  return (
    <main className={classes.main}>
      <AuthenticatedSiteHeader />
      <article>
        <header>
          <h1>selección de rol</h1>
        </header>
        <section>
          <p>bienvenida/o <strong><span className='profile-name'>{currentProfile.name}</span></strong></p>
          <p>tienes acceso como administrador de sistema y también como cliente.</p>
          <nav>
            <p>elige un camino para continuar:</p>
            <ul>
              <li data-theme='admin'>
                <h2>admin <span className='acronym'>BUP</span></h2>
                <p>Aquí puedes administrar clientes y ver encuestas</p>
                <p><Link role='button' to='/admin'>acceso admin <span className='acronym'>BUP</span></Link></p>
              </li>
              <li data-theme='client'>
                <h2>cliente</h2>
                <p>aquí puedes ver tus datos y contestar encuestas.</p>
                <p><Link role='button' to='/user'>acceso cliente</Link></p>
              </li>
            </ul>
          </nav>
        </section>
      </article>
    </main>
  )
}
