import classes from '../../pages/admin/people-new-page.module.scss'
import { useRef, useState } from 'react'
import { Select } from '@headlessui/react'
import { useAuthFetch } from '../../services/api'

const genericErrorMessage = 'Error loading companies'

// TODO: refactor this form in a reusable way
// TODO: add dynamic validation for email
// TODO: add dynamic validation for password, The password must be a string with at least 6 characters
export function CreateAdminForm ({ createAdmin }) {
  const { data, error } = useAuthFetch('/api/companies')
  const companies = []
  companies.push({
    id: 0,
    name: 'Escoge una empresa'
  })
  const [selectedCompany, setSelectedCompany] = useState(companies[0])

  const formRef = useRef()
  const formData = [
    {
      name: 'name',
      value: 'Nombre'
    },
    {
      name: 'email',
      value: 'Correo electrónico'
    },
    {
      name: 'password',
      value: 'Contraseña'
    }
  ]
  if (error) {
    return <p>{genericErrorMessage}</p>
  }
  if (data !== undefined && data !== null && !Array.isArray(data)) { // return error if data is not an array
    return <p>{genericErrorMessage}</p>
  }
  data?.forEach(company => {
    companies.push(company)
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const name = formData.get('name')
    const email = formData.get('email')
    const password = formData.get('password')
    const admin = { name, email, password }
    const bodyData = {
      admin
    }
    if (selectedCompany.id && selectedCompany.id.toString() !== '0') {
      bodyData.membership = { companyId: selectedCompany.id }
    }
    setSelectedCompany(companies[0])
    createAdmin(bodyData).then(() => formRef.current.reset())
  }

  const inputs = formData.map(({ name, value }) => (
    <div className={classes.formGroup} key={name}>
      <label htmlFor={name}>{value}: </label>
      <input name={name} required />
    </div>
  ))

  const optionsCompanyInput = companies.map(company => ({ id: company.id, value: company.id, label: company.name }))

  const handleChange = (event) => {
    const position = companies.findIndex(company => company.id.toString() === event.target.value.toString())
    setSelectedCompany(companies[position])
  }
  // TODO: how to handle companies with the same or similar name
  return (
    <form
      id='create-admin-form'
      onSubmit={handleSubmit}
      className={classes.form}
      ref={formRef}
    >
      {inputs}
      {
        error
          ? <p>{genericErrorMessage}</p>
          : <Select
              defaultValue={optionsCompanyInput[0]}
              onChange={handleChange}
              name='company'
              aria-label='Company Name'
            >
            {
              optionsCompanyInput.map(option => (
                <option
                  key={option.id}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))
            }
            {/* eslint-disable-next-line react/jsx-indent */}
            </Select>
      }
      <button className={classes.button} type='submit'>Crear Admin</button>
    </form>
  )
}
