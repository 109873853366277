import * as Sentry from '@sentry/react'

const debug = import.meta.env.VITE_MONITOR_DEBUG === 'true'
const verbose = import.meta.env.VITE_MONITOR_VERBOSE === 'true' || import.meta.env.DEV

const config = {
  debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.VITE_SENTRY_ENABLED !== 'false' && (import.meta.env.VITE_SENTRY_ENABLED === 'true' || ['production', 'staging'].includes(import.meta.env.BUP_ENV)),
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || import.meta.env.BUP_ENV || 'unknown',
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.05,
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.05,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 0.05,
  ignoreErrors: [
    // "TypeError: can't access dead object",
    // "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    // "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node."
  ]
}

if (debug) console.info('[monitor]', 'config', config)

if (verbose) console.info('[monitor]', 'initializing...')
if (config.dsn && config.dsn.match(/\.sentry\.io/)) {
  try {
    Sentry.init({
      ...config,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ]
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/] // TODO: check this later
    })
    if (verbose) console.info('[monitor]', 'initialized.')
  } catch (error) {
    if (verbose) console.warn('[monitor]', 'sentry intialization failed', error.message)
  }
} else {
  if (verbose) console.warn('[monitor]', 'sentry intialization failed', 'dsn is missing or invalid')
}

export function captureException (...args) {
  return Sentry.captureException(...args)
}

export default {
  captureException
}
