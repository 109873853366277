import { useState } from 'react'
import { Button, Field, RadioGroup } from '@headlessui/react'
import { RequiredRadio } from '../../forms/required-radio.jsx'

const MatrixQuestion = ({ question, currentResponse, createSurveyEventResponse, onSubmitSuccess }) => {
  const [selected, setSelected] = useState(currentResponse?.content.answers || [])
  const { answersOptions: templateAnswers, subquestions: options, code: questionCode } = question
  const createResponseAndChangePanel = (event) => {
    event.preventDefault()
    createSurveyEventResponse({
      content: {
        questionId: questionCode,
        answers: selected
      }
    })
      .then(() => onSubmitSuccess())
  }

  const selectAnswerForOption = value => {
    setSelected(prev => ([...prev.filter(p => p.option !== value.option), value]))
  }

  const inputs = (
    <div className='overflow-x-auto'>
      <table className='min-w-full border-collapse'>
        <thead>
          <tr>
            <th className='px-4 py-2' colSpan={1} />
            {templateAnswers.map((header, index) => (
              <th key={index} className='px-4 py-2 text-left'>
                {header.option}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {options.map((option) => {
            const subquestionSelected =
          selected.find((s) => s.option === option.code) || null
            return (
              <RadioGroup
                value={subquestionSelected || ''}
                by='code'
                as='tr'
                onChange={selectAnswerForOption}
                aria-label={question.subdimension}
                key={option.code}
                className='border-t'
              >
                <td className='px-4 py-2'>{option.text}</td>
                {templateAnswers.map((templateAnswer) => {
                  const value = { option: option.code, code: templateAnswer.code }
                  return (
                    <Field as='td' key={templateAnswer.code} className='px-4 py-2'>
                      <RequiredRadio value={value} name={`template-${templateAnswer.code}-option-${option.code}`} selected={subquestionSelected} />
                    </Field>
                  )
                })}
              </RadioGroup>
            )
          })}
        </tbody>
      </table>
    </div>
  )
  return (
    <form id={questionCode} onSubmit={createResponseAndChangePanel}>
      {inputs}
      <div className='m-4 flex  justify-end'>
        <Button type='submit'>Siguiente</Button>
      </div>
    </form>
  )
}

export default MatrixQuestion
