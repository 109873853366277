import { Link } from 'react-router-dom'

export function Company () {
  return (
    <ul>
      <li><Link to='memberships'>Colaboradores</Link></li>
      <li><Link to='survey_events'>Encuestas</Link></li>
    </ul>
  )
}
