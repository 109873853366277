import { Navigate, Outlet } from 'react-router-dom'
import { useCurrentProfile } from '../../services/api.js'
import { Breadcrumbs } from '../../components/breadcrumbs.jsx'
import { AuthenticatedSiteHeader } from '../../components/authenticated-site-header.jsx'
import classes from './user.module.scss'

export function UserLayout () {
  const currentProfile = useCurrentProfile()
  if (!currentProfile.isUser) {
    if (currentProfile.isAdmin) return <Navigate to='/admin' replace />
    return <Navigate to='/user' replace />
  }

  return (
    <main className={classes.main} data-theme='client'>
      <AuthenticatedSiteHeader />
      <article>
        <nav aria-label='breadcrumb'>
          <Breadcrumbs />
        </nav>
        <Outlet />
      </article>
    </main>
  )
}
