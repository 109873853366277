import React, { useState } from 'react'
import { Button } from '@headlessui/react'
import Select from 'react-select'
// import Spinner from '../../spinner.jsx'

// import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
// const MultipleChoiceQuestion = ({ answers }) => {
//   const [selectedPeople, setSelectedPeople] = useState([])
//   const [query, setQuery] = useState('')

//   const people = answers.map(({ value, label }) => ({ id: value, name: label }))

//   const filteredPeople =
//     query === ''
//       ? people
//       : people.filter((person) => {
//         return person.name.toLowerCase().includes(query.toLowerCase())
//       })

//   return (
//     <Combobox immediate multiple value={selectedPeople} onChange={setSelectedPeople} onClose={() => setQuery('')}>
//       {selectedPeople.length > 0 && (
//         <ul>
//           {selectedPeople.map((person) => (
//             <li key={person.id}>{person.name}</li>
//           ))}
//         </ul>
//       )}
//       <ComboboxInput aria-label='selected people' onChange={event => setQuery(event.target.value)} />
//       <ComboboxOptions anchor='bottom' className='w-52 border empty:invisible'>
//         {filteredPeople.map((person) => (
//           <ComboboxOption key={person.id} value={person} className='data-[focus]:bg-user-primary-100'>
//             {person.name}
//           </ComboboxOption>
//         ))}
//       </ComboboxOptions>
//     </Combobox>
//   )
// }

const MultipleQuestion = ({ question, currentResponse, createSurveyEventResponse, onSubmitSuccess }) => {
  const [selected, setSelected] = useState(question.answers.filter(answer => currentResponse?.content.answers.includes(answer.value)))
  const onSubmit = event => {
    event.preventDefault()
    if (selected.map(option => option.value).sort().join(', ') !== currentResponse?.content.answers.sort().join(', ')) {
      const data = {
        content: {
          questionId: question.code,
          answers: selected.map(option => option.value)
        }
      }
      createSurveyEventResponse(data)
        .then(() => onSubmitSuccess())
    } else {
      return onSubmitSuccess()
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#fdfdfd',
      borderColor: 'transparent',
      boxShadow: 'none',
      border: '#fdbfff 1px solid',
      outline: state.isFocused ? '1px solid #ff8dfc' : 'none',
      overflow: 'hidden',
      '&:hover': {
        border: '#ff8dfc 1px solid'
      }
    }),
    input: (provided) => ({
      ...provided,
      border: '0 none'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      opacity: 0.6,
      fontSize: '10px',
      padding: '0px'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#ff8dfc' : '#fdbfff',
      ':hover': {
        color: '#fdbfff'
      },
      backgroundColor: '#fdfdfd'

    })
  }

  return (
    <form id={question.code} onSubmit={onSubmit}>
      <Select
        isMulti
        required
        className='sm:w-1/2 focus:outline-non'
        closeMenuOnSelect={false}
        onChange={setSelected}
        value={selected}
        options={question.answers.map(({ value, label }, index) => ({ id: index, value, label }))}
        styles={customStyles}
      />
      <div className='m-4 flex  justify-end'>
        <Button type='submit'>Siguiente</Button>
      </div>
    </form>
  )
}

export default MultipleQuestion
