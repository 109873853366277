import { Link, useParams } from 'react-router-dom'
import { useAuthFetch } from '../../../services/api'
import { handleTimeActions } from '../../../helpers/helpers'

const SurveyEventsTable = ({ surveyEvents }) => (
  <table>
    <thead>
      <tr>
        <th>Nombre Encuesta</th>
        <th>Apertura</th>
        <th>Cierre</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      {surveyEvents.map((surveyEvent) => (
        <tr key={surveyEvent.id}>
          <td>{surveyEvent.content.title || 'not set'}</td>
          <td>{handleTimeActions(surveyEvent.content.opensAt)}</td>
          <td>{handleTimeActions(surveyEvent.content.closesAt)}</td>
          <td>
            <Link to={surveyEvent.id}>ver</Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export function AdminCompanySurveyEventsPage () {
  const { companyId: paramsCompanyId } = useParams()
  const companyId = paramsCompanyId
  const { data, isLoading, error } = useAuthFetch(`/api/companies/${companyId}/survey_events`)

  if (isLoading) { return <p>Loading...</p> }
  if (!companyId) { return <p>No company id</p> }
  if (error) { return <p>{error.message}</p> }

  return (
    <>
      <header>
        <h3>Administrador de encuestas</h3>
      </header>
      <section>
        <div className='flex justify-end w-full'>
          <Link className='mb-4' to='new'>Nueva encuesta</Link>
        </div>
        {data && <SurveyEventsTable surveyEvents={data} />}
      </section>

    </>
  )
}
