import surveyInstrument from '../../../data/survey-questions'
import ConsentQuestion from './consent-question'
import DimensionsTabGroup from './dimensions-tab-group'

const SurveyAppQuestions = ({ onAfterLastDimension, responses }) => {
  const isConsentGranted = !!responses.find(response => response.content.consent === 'yes')

  return (
    isConsentGranted
      ? <DimensionsTabGroup
          groups={surveyInstrument}
          onAfterLastGroup={onAfterLastDimension}
          name='dimension'
        />
      : <ConsentQuestion />

  )
}

export default SurveyAppQuestions
