import { Navigate, useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../hooks/use-current-user'
import { LoginForm } from '../components/login/form'

export function LoginPage () {
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  if (currentUser) { return <Navigate to='/' replace /> }

  const handleOnSuccess = () => {
    navigate('/login')
  }

  return (
    <>
      <h1>ingresa con tu cuenta</h1>
      <LoginForm onSuccess={handleOnSuccess} />
    </>
  )
}
