import { useContext } from 'react'
import { SurveyEventContext } from '../../../contexts/survey-event'

const MatrixAnswers = ({ question, currentResponse }) => {
  const { subquestionsType } = question
  const { populationLabelById } = useContext(SurveyEventContext)
  if (!currentResponse || !question) return <p>A: sin respuesta </p>
  const { answers } = currentResponse.content

  const getAnswertext = (optionCode) => {
    const anwserText =
    question.answersOptions.find(option => option.code === optionCode)
    return anwserText.option
  }

  const getQuestionText = (questionCode) => {
    const anwserText =
    question.subquestions.find(option => option.code === questionCode)
    return anwserText.text
  }

  if (subquestionsType) {
    return (
      answers.map((answer, idx) => (
        <div key={idx} className='text-user-primary-600'>
          {populationLabelById(answer.option)} : {getAnswertext(answer.code)}
        </div>
      ))
    )
  } else {
    return (
      answers.map((answer, idx) => (
        <div key={idx}>
          {getQuestionText(answer.option)}:
          <span className='text-user-primary-600'>
            {getAnswertext(answer.code)}
          </span>
        </div>
      ))
    )
  }
}

export default MatrixAnswers
